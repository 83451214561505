// refer to https://www.npmjs.com/package/react-toastify
import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const notify = (message, type = "info") => {

    const toastFunction = toast[type];

    toastFunction(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    })
};
function ToastDefault() {

    return (
        <>
            <ToastContainer />
        </>


    );



}

export default ToastDefault;