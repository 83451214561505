import React, { useEffect, useRef, useState } from 'react'
import TinyMceEditor from '../common/TinyMceEditor'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_TEXT_AND_SHORT_TEXT } from '../../constant/constant';

export default function EditorContentChangeModel({ setIsEditorVisible, data }) {
    const { body, path, changeKey, anotherData } = data;

    const [editorContent, setEditorContent] = useState(body);

    const { crudAction } = useSelector(state => state);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleEditorChange = (newValue, editor) => {
        setEditorContent(newValue);
    }

    /**
     * Save the updated content of TinyMCE editor to the database
     * @function
     * @returns {void}
     */
    const handleEditorSaveContent = () => {

        if (editorContent && path) {
            let setData = {
                [changeKey]: editorContent,
                ...anotherData
            }
            setLoading(true);
            let propt = (anotherData) ? Object.keys(anotherData).concat(changeKey) : [changeKey];
            dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, key: path, value: setData, propt: propt });
        }
    }


    useEffect(() => {
        if (crudAction.type == "edit") {
            setIsEditorVisible(false);
        }
        setLoading(false);

    }, [crudAction])

    return (
        <>
            <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" style={{ "display": "block", "paddingRight": "15px" }} aria-modal="true">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content bg-light shadow rounded-4" style={{ "border": "1px solid rgba(102,88,155,0.3)" }}>
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">Change Content</h5>
                            <button type="button" onClick={() => { setIsEditorVisible() }} className="btn-close"></button>
                        </div>
                        <div className="modal-body h-100">
                            <TinyMceEditor data={body} index={0} onChangeValue={handleEditorChange} />
                            <div className="my-4 text-end">
                                <Button className={`bg-primary border-0`} onClick={handleEditorSaveContent} disabled={loading}>
                                    Save Changes</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
