import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPenToSquare, faHouseChimney, faPen, faCheck, faCross, faXmark, faTrash, faLeftLong, faCirclePlus, faListOl, faMagnifyingGlass, faChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef } from 'react';
import { ContentPage, EditContentElementPage, ListPage, EDIT_TEXT_AND_SHORT_TEXT, DELETE_SECTION, SET_ORDER_PAGE, QBankQuestion } from '../../constant/constant';
import { useNavigate, useLocation } from "react-router-dom";
import TinyEditorSection from './TinyEditorSection';
import loadingImage from '../../assets/images/loading2.gif'
import { useDispatch, useSelector } from 'react-redux';
import { setEditedStatus, deleteData, errorInOperation } from '../../redux/features/fetchAllRecord';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import AddNewRightSideModel from './AddNewRightSideModel';
import Swal from 'sweetalert2';
import { recursionData, reduxSearchData } from '../../commanFunction';
import AddNewTopModel from './AddNewTopModel';
import Accordion from 'react-bootstrap/Accordion';
import OrderListComponet from './OrderListComponet';
import useFetch from '../../global/callMasterData';
import { Editor } from '@tinymce/tinymce-react';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import AddNewQBankSectionTopModel from './AddNewQBankSectionTopModel';
import Question from '../subComponets/Question';
import EditorContentChangeModel from '../modal/EditorContentChangeModel';


function QBankSection({ error, userId }) {

  const dispatch = useDispatch();

  // toggle add new model section 
  const [isModelVisible, setIsModelVisible] = useState(false);

  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const [editorData, setEditorData] = useState({});

  const navigate = useNavigate();

  const location = useLocation();

  const serachbarRef = useRef("");

  const [orderListView, setOrderListView] = useState(false);

  // let reduxData = useSelector(state => state.AllData);

  let allReduxData = useSelector(state => state.AllData);

  let [reduxData, setReduxData] = useState(allReduxData);

  // if(allReduxData.length == 0 || allReduxData.policies == undefined){

  // }
  let reduxDataCrudAction = useSelector(state => state);

  let [searchData, setSearchData] = useState(reduxData);
  let [userKey, setUserKey] = useState("");

  const [searchDataChild, setSearchDataChild] = useState([]);


  // loading button use state for submiting a new data
  const [isLoading, setLoading] = useState(false);

  // loading for search data in redux 
  const [loadingForSearchData, setLoadingForSearchData] = useState(true);

  // navigate link destination use state
  const [linkDestination, setLinkDestination] = useState("");

  // for reorder data manage state 
  const [reorderData, setReorderData] = useState([]);

  // manage status state 
  useEffect(() => {

    if (reduxDataCrudAction.crudAction != "" || reduxDataCrudAction.error != null) {

      if (reduxDataCrudAction.crudAction.type == "edit") {

        notify(" Data Updated Successfully!");

        setReorderData([]);
        setLoading(false);
        // notify(" Updated to '" + reduxDataCrudAction.crudAction.value + "'");

      } else if (reduxDataCrudAction.crudAction.type == "delete") {
        // notify(" Deleted '" + reduxDataCrudAction.crudAction.value + "'", "error");
        Swal.fire({
          title: "Deleted!",
          text: "\"" + reduxDataCrudAction.crudAction.value + "\" has been deleted.",
          icon: "success",
          showConfirmButton: false,
          timer: 2500
        });
      } else if (reduxDataCrudAction.crudAction.type == "addNew") {

        if (reduxDataCrudAction.crudAction.navPush == EditContentElementPage) {
          notify("New Section '" + reduxDataCrudAction.crudAction.value + "' Added!", "success");
          addNewTopModel();
        } else {
          notify("New Value '" + reduxDataCrudAction.crudAction.value + "' Added!", "success");
          toggleModelVisibility();
          setLoading(false);
        }


      } else if (reduxDataCrudAction.error != "") {

        notify(reduxDataCrudAction.error, "error");
        dispatch(errorInOperation(null));
        setLoading(false);

      }
      dispatch(setEditedStatus(""));
    }


  }, [reduxDataCrudAction])

  // handle toggale edit event 
  const [editValue, setEditValue] = useState({});
  const handleToggleEditEvent = (index, editValue = "") => {

    setEditedValue({ value: editValue, key: "" })
    setEditValue({ index: index })


  }

  // edited value with inplace editing 
  const [editedValue, setEditedValue] = useState({
    value: "",
    key: ""
  });

  let value, key;
  const getEditedValue = (e) => {
    value = e.target.value;
    key = e.target.getAttribute("data-key");
    setEditedValue({
      value: value,
      key: key
    })

  }

  function edit({ propt }) {

    if (editedValue.key != "" && editedValue.value.trim() != "") {

      if (propt.length > 0 && propt[0] == "title") {

        dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { 'title': editedValue.value }, key: editedValue.key, propt, contentType: "policy" });

      } else {

        dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { 'text': editedValue.value, 'shortText': editedValue.value }, key: editedValue.key, propt, contentType: "policy" });

      }
      setEditedValue({
        value: "",
        key: ""
      });
      setEditValue({});

    }

    else {

      notify("No Changes Made To Save!", "error");
    }

  }
  /////////// close editing section ///////////////
  // manage delete a specific delete 
  function handleDeleteEvent(key, deleteValue) {
    Swal.fire({
      title: "Are you sure you want to delete \"" + deleteValue + "\" ?",
      text: "All child node of \"" + deleteValue + "\" will also be deleted",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: DELETE_SECTION, key: key, value: deleteValue })
        // dispatch(deleteData({ key: key, value: deleteValue }))
      }
    });


  }

  const toggleModelVisibility = (destination) => {
    setLinkDestination((destination != undefined) ? destination : "/");
    setIsModelVisible(!isModelVisible);
  };

  // -----------------navigate to page with key--------------------
  function navigateWithKey(key, navigationType = "") {
    // setSearchData({});
    if (navigationType != "" && navigationType == "bySearch") {

      serachbarRef.current.value = "";

      navigate("/", { state: { key, type: "bySearch" } });


    } else {

      navigate("/policy", { state: { key } });
    }
    setEditValue({});
    setEditedValue({
      value: "",
      key: ""
    });
    setOrderListView(false)
    setIsModelVisible(false);
    setIsTopModelVisible(false);


  }

  function navigateByDireact(type = "home") {
    if (type == "back") {
      navigate(-1)
    } else {
      navigate("/policy");
    }
    setEditValue({});
    setEditedValue({
      value: "",
      key: ""
    });
    setOrderListView(false)
    setIsModelVisible(false);
    setIsTopModelVisible(false);
  }
  // -----------------------------------------------//

  useEffect(() => {

    if (location.state != null) {

      const keyToFind = location.state.key;

      let searchDataType = "onlyKey";

      if (location.state.type == "bySearch") {

        searchDataType = reduxDataCrudAction.rootLevelKey.find((element) => element == keyToFind) == undefined ? "onlyKey" : "";
      }

      //for redirect too root level if search in root level
      let recursionDataResponse = recursionData(allReduxData, keyToFind, searchDataType);

      if (Object.entries(recursionDataResponse).length > 0) {

        // reduxData = recursionDataResponse;
        setReduxData(recursionDataResponse);
        setSearchData(recursionDataResponse);

        // scrollToSection(location?.state?.hitKey);
        // searchData = recursionDataResponse;


      } else if (location.state.type != "bySearch") {
        navigate(-1);
      }

    } else {
      // setReduxData(allReduxData);
      // setSearchData(allReduxData);
      navigate(-1);
    }
    setLoadingForSearchData(false);

  }, [location, allReduxData])
  // ----------------top model visable -------------------//
  const [isTopModelVisible, setIsTopModelVisible] = useState(false);
  const addNewTopModel = (destination) => {
    setLinkDestination((destination != undefined) ? destination : "/policies/documents/" + userKey + "/");
    setIsTopModelVisible(!isTopModelVisible)
  }

  // -----------------navigate to smooth scroll --------------------
  function scrollToSection(sectionId) {

    var section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (document.getElementsByClassName('scrollActiveClass').length > 0) {
        Array.from(document.getElementsByClassName('scrollActiveClass')).forEach(element => {
          element.classList.remove('scrollActiveClass');
        });
      }
      document.getElementById('sideNavId_' + sectionId).classList.add('scrollActiveClass');
      section.classList.add('highlightOnScroll');
      // document.getElementById('headingToHihlight_' + sectionId).classList.add('highlightOnScroll');
      setTimeout(function () {
        section.classList.remove('highlightOnScroll');
        // document.getElementById('headingToHihlight_' + sectionId).classList.remove('highlightOnScroll');


      }, 3000);
    } else {

      // navigateWithKey(sectionId, "bySearch");
    }
  }

  // -----------------search bar event --------------------
  function setSearch(searchValue) {

    if (searchValue.length > 0) {

      let recursionDataResponse = reduxSearchData(allReduxData?.policies, searchValue)

      if (Object.entries(recursionDataResponse).length > 0) {

        // for sorting in alphabetical order
        setSearchData(recursionDataResponse.sort((a, b) => a.text.localeCompare(b.text)))



      } else {
        setSearchData({})
      }
    } else {

      setSearchData(reduxData)
    }

  }

  const handleEditorChange = (value) => {
    setEditorData({
      body: value?.body || "",
      path: value?.path || "",
      changeKey: value?.changeKey || "",
    });
    setIsEditorVisible(!isEditorVisible);
  }

  return (
    <>
      <ToastDefault />

      {isModelVisible && <AddNewQBankSectionTopModel linkDestination={linkDestination} toggleModelVisibility={toggleModelVisibility} />}

      {isEditorVisible && <EditorContentChangeModel setIsEditorVisible={handleEditorChange} data={editorData} />}

      {
        (!error) ?

          <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>

            <img src={require('../../assets/images/loading2.gif')} ></img>

          </div>
          :
          <>
            {
              (Object.keys(reduxData).length > 0 && !loadingForSearchData) ?
                <div className="container-fluid disable-text-selection"
                  style={{ paddingLeft: "130px", "marginTop": "8rem" }} >
                  <div className="row ">
                    {/* top home section  */}
                    <div className='col-md-3 ps-5'>
                      <h1 onClick={() => { navigateByDireact("home") }}><FontAwesomeIcon className='text-primary' icon={faHouseChimney} /></h1>

                      {(location.state != null) ?
                        <button type="button" className="btn btn-primary ms-3" onClick={() => { navigateByDireact("back") }}>
                          <FontAwesomeIcon icon={faLeftLong} />
                          &nbsp;  Back</button>

                        : ""
                      }

                    </div>
                    <div className="col-md-9 text-end ">
                      <button type="button" className="btn btn-primary  top-right-button me-2" onClick={() => { toggleModelVisibility(reduxData?.linkDestination) }} >
                        <FontAwesomeIcon icon={faPlus} />ADD NEW QUESTION</button>
                    </div>
                    {/* top home section end */}
                    {/* upper top main section  */}
                    <>
                      <div className="col-9 offset-3 list" data-check-all="checkAll">
                        <div className="card d-flex flex-row mb-3 ">
                          <div className=" flex-grow-1 min-width-zero">
                            <div className="card-body align-self-center flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                              <div className='row list-item-heading pb-3'>
                                <div className='col-md-8 '>
                                  <span className='display-6'> {reduxData?.text || "NO NAME"}</span>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    {/* upper top main section end */}
                    {/* side link nav bar start */}
                    <div className="col-3 " >
                      <div className="card position-fixed leftSideScrollBar" style={{ left: "130px", width: "25rem", height: "60vh", overflowY: "auto", scrollbarWidth: "none", top: "12rem" }}>
                        <div className="card-body">
                          {/* <div className="" style={{ padding: "0px 0rem 0px 1.8rem" }}>
                            <div style={{
                              border: "1px solid #d7d7d7",
                              borderRadius: "5px",
                              background: " #f8f8f8",

                            }}>
                              <input id="searchBar" ref={serachbarRef} placeholder="Search..." style={{
                                border: "initial",
                                outline: "none",
                                background: "0 0",
                                // outline: "initial !important",
                                fontSize: ".8rem",
                                width: "93%",
                                color: "#3a3a3a",
                                padding: "0.4rem 1rem",
                                lineHeight: "2",

                              }}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              <span className="search-icon"
                                style={{
                                  fontSize: "17px",
                                  borderRadius: "10px",
                                  color: "#d7d7d7",
                                  width: "40px",
                                  height: "40px",
                                  right: "3px",
                                  textAlign: "center",
                                  cursor: "pointer"
                                }}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                              </span>
                            </div>
                          </div> */}
                          <ul >
                            {
                              (reduxData?.text) ?
                                Object.values(((Object.entries(searchData).length > 0) ? searchData : reduxData)).map((key, index) => {
                                  if (key.navPush === QBankQuestion) {
                                    return (
                                      <>
                                        <li className={`actifve border-bottom my-3 justify-content-between d-flex align-items-center`} id={'sideNavId_' + key.key} style={{ listStyleType: "none", fontSize: "1.1rem", backgroundColor: (key?.publishStatus) ? "#e2d1f1" : "", padding: "5px 10px 5px 10px", borderRadius: "5px" }} key={"sidebar_" + index} >
                                          <div className=' d-flex justify-content-between ' onClick={() => scrollToSection(key.key)} style={{ cursor: "pointer" }}>
                                            <span style={{ "maxWidth": "100%" }}>   {(key.subject) ? key.subject : "No Name"}   </span>
                                            &nbsp;
                                          </div>
                                          <FontAwesomeIcon icon={faChevronRight} opacity={0.5} />
                                          {(searchDataChild.length > 0) ? searchDataChild.map((key, index) => {
                                            <div style={{ fontSize: "0.78rem" }}>
                                              {key.subject}
                                            </div>

                                          }) : ""}

                                        </li>
                                      </>
                                    )
                                  }
                                  else {
                                    return (<>
                                      {index == 0 &&
                                        <li className="actifve p-2 border-bottom my-3 " id={'sideNavId_1'} style={{ listStyleType: "none", fontSize: "1.1rem" }} key={"sidebar_1"}>
                                          <div className=' d-flex justify-content-between '>
                                            {"No Data To Display"}
                                            <FontAwesomeIcon icon={faChevronRight} opacity={0.5} />
                                          </div>

                                        </li>}
                                    </>)
                                  }
                                })
                                :
                                <li className="actifve p-2 border-bottom my-3 " id={'sideNavId_1'} style={{ listStyleType: "none", fontSize: "1.1rem" }} key={"sidebar_1"}>
                                  <div className=' d-flex justify-content-between '>
                                    {"No Data To Display"}
                                    <FontAwesomeIcon icon={faChevronRight} opacity={0.5} />
                                  </div>

                                </li>
                            }

                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* side link nav bar end */}
                    {/* main section */}
                    <div className="col-9 ">
                      <div className="col-12 list list__item is-idle js-item" data-check-all="checkAll" data-id={linkDestination}>

                        {/* main card section start here */}
                        {
                          Object.values(reduxData).map((key, index) => {

                            if (key.navPush == QBankQuestion) {
                              return (
                                <>
                                  <Question
                                    data={key}
                                    index={index}
                                    handleDeleteEvent={handleDeleteEvent}
                                    isLoading={isLoading}
                                    setLoading={setLoading}
                                    setIsEditorVisible={handleEditorChange}
                                  />
                                </>
                              )
                            } else {
                              return (<>
                                {index == 0 && <div className='text-center bg-white p-5 h3'>No Data To Display</div>}
                              </>)
                            }
                          })
                        }
                        {/* main section end */}
                      </div>

                    </div>
                    {/* main section end */}
                  </div>
                </div>
                :
                <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>
                  <img src={require('../../assets/images/loading2.gif')} ></img>

                </div>
            }
          </>
      }

    </>
  )
}

export default QBankSection