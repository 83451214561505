import React from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addNewData } from '../../redux/features/fetchAllRecord';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import { consoleFxn } from '../../console/console';
import { ADD_NEW_AT_ROOT, QBankCategory } from '../../constant/constant';


const AddNewRightSideModel = ({ toggleModelVisibility, linkKey, loading, totalItemsLength, type }) => {

    const dispatch = useDispatch()
    // on add new form submit 
    const [addNew, setAddNewInput] = useState({
        name: "",
        pageType: (type == QBankCategory) ? QBankCategory : "",
    });

    let name, value;
    const getRecord = (event) => {

        name = event.target.name;
        value = event.target.value;
        setAddNewInput({ ...addNew, [name]: value });
    }
    const handleSubmit = (event) => {

        event.preventDefault();
        if (addNew.pageType != "" && addNew.name.trim() != "") {
            dispatch({ type: ADD_NEW_AT_ROOT, linkDestination: linkKey, navPush: addNew.pageType, text: addNew.name, shortText: addNew.name, orderInPage: totalItemsLength, contentType: type });
            loading.setLoading(true);
            event.target.reset();

        } else {
            notify("All Field Must Be Filled", "error");
        }

    }

    return (
        <>
            <div className="modal modal-right show d-block" id="exampleModalRight" tabIndex="-1" role="dialog" aria-labelledby="exampleModalRight" style={{ "paddingRight": "17px", "background": "rgba(0,0,0,0.7)" }} aria-modal="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">Add New {type == QBankCategory ? "Category" : "Page"}</h5>

                            <button type="button" onClick={() => toggleModelVisibility()} className="btn-close"></button>

                        </div>
                        <div className="modal-body">

                            <form onSubmit={(e) => handleSubmit(e)}  >
                                {(type != QBankCategory)
                                    &&
                                    <div className='row'>
                                        <div className='col-md-6 fs-5'>
                                            <Form.Check
                                                type="radio"
                                                id="lPage"
                                                label="List Page"
                                                name='pageType'
                                                value="ListPage"
                                                onChange={(e) => getRecord(e)}
                                                size="xl" />
                                        </div>
                                        <div className='col-md-6 fs-5'>
                                            <Form.Check
                                                type="radio"
                                                name='pageType'
                                                value="ContentPage"
                                                onChange={(e) => getRecord(e)}
                                                id="CPage"
                                                label="Content Page" />
                                        </div>
                                    </div>
                                }

                                <Form.Group className="mt-3" controlId="formBasicEmail">
                                    <Form.Control autoFocus type="text" placeholder="Enter Name" onChange={(e) => getRecord(e)} name='name' />
                                </Form.Group>
                                <div className='text-end'>
                                    <Button variant="primary" disabled={loading.isLoading} className='mt-3' type="submit">
                                        {loading.isLoading ? 'Submiting…' : 'Submit'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewRightSideModel
