
import { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';
import { storage } from '../../firebaseDb/config/firebase';
import { FIREBASE_STORAGE_PATH } from '../../constant/constant';
import DeepLinkSection from '../main/DeepLinkSection';
import { useSelector } from 'react-redux';

export default function TinyMceEditor({ onChangeValue, data, index }) {

    const [DeepLinkVisiblity, setDeepLinkVisiblityFn] = useState(false);
    const [activeEditorIndex, setActiveEditorIndex] = useState(0);
    let DeepLinkData = useSelector(state => state.AllData);

    /**
     * @function image_upload_handler_fun
     * @description This function is to be used as the image upload handler for TinyMCE editor.
     * It will upload the image to Firebase Storage and return the download URL to be used in the editor.
     * @param {Object} blobInfo - The file data to be uploaded.
     * @param {Function} progress - A callback function to report the progress of the upload.
     * @returns {Promise<string>} - A promise that resolves with the download URL of the uploaded image.
     * @throws {Error} - An error if the upload or getting the download URL fails.
     */
    const image_upload_handler_fun = (blobInfo, progress) => new Promise((resolve, reject) => {

        const file = (typeof blobInfo.blob === "function") ? blobInfo.blob() : blobInfo;

        const storageRef = ref(storage, FIREBASE_STORAGE_PATH + uuid() + "_" + file.name);

        uploadBytes(storageRef, file).then((snapshot) => {

            getDownloadURL(snapshot.ref).then((downloadURL) => {

                resolve(downloadURL);

            }).catch((error) => {

                reject("Error getting download URL:", error);
                // failure(error);
            });
        }).catch((error) => {

            reject("Error uploading image:", error);
        });

    });


    const toggleDeepLinksectionVisiblity = (index) => {

        setDeepLinkVisiblityFn(!DeepLinkVisiblity);
        setActiveEditorIndex(index)
    }

    return (
        <>
            <DeepLinkSection show={DeepLinkVisiblity} onHide={() => setDeepLinkVisiblityFn(false)} data={DeepLinkData.editorContent} toggleDeepLinksectionVisiblity={toggleDeepLinksectionVisiblity} activeEditorIndex={activeEditorIndex} />

            <Editor
                apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                onEditorChange={(newValue, editor) => onChangeValue(newValue,editor)}
                initialValue={data}

                id={"myEditor"}

                init={{

                    data: index,

                    height: 500,

                    selector: "#myEditor",

                    images_upload_handler: image_upload_handler_fun,

                    menubar: false,

                    toolbar_mode: 'scrolling',

                    plugins: ["anchor", "autolink", "autoresize", "codesample", "spellchecker", "paste", "image", "file", "imagetools", "lists", "link", "media", "noneditable", "table", "template"],

                    toolbar: "urldialog  undo redo | fontselect | fontsizeselect | styleselect | bold italic underline strikethrough | justifyleft justifycenter justifyright justifyfull | bullist numlist | outdent indent hr |  link unlink | image | file | sub sup | forecolor backcolor forecolorpicker backcolorpicker|  removeformat formatselect | tablecontrols table row_props cell_props delete_col delete_row col_after col_before row_after row_before split_cells merge_cells | indigoDeepLink |fontsize",
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; overflow-y:scroll !important; }',

                    setup: (editor) => {

                        editor.ui.registry.addButton('urldialog', {
                            icon: 'browse',
                            text: 'Deep Link',
                            onAction: () => {
                                toggleDeepLinksectionVisiblity("#myEditor")

                            }
                        });

                        window.addEventListener("message", (event) => {
                            if (event.data.cmd == "insertDeepLink_" + "#myEditor") {
                                let name = event.data.value.name;
                                let pageType = event.data.value.pageType;
                                let linkDestination = event.data.value.linkDestination;
                                editor.insertContent(`[deeplink]${pageType}|${linkDestination}|${name}[/deeplink]`);
                            }
                        });


                    },

                }
                }
            />
        </>
    )
}
