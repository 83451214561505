import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ContentPage, EditContentElementPage, ListPage, DELETE_SECTION, FULL_CONTENT_ACCESS, POLICIES_PAGE_ACCESS, DELETE_USER } from '../../constant/constant';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import loadingImage from '../../assets/images/loading2.gif'
import { useNavigate, useLocation } from "react-router-dom";
import HospitalModel from './HospitalModel';
import InstitutionModel from './InstitutionModel';
import Swal from 'sweetalert2';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import { setEditedStatus, deleteData, errorInOperation } from '../../redux/features/fetchAllRecord';
import AddNewPhoneDirectoryModel from './AddNewPhoneDirectoryModel';
import AddNewUserModel from './AddNewUserModel';

const RolesAndPermissions = ({ error }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [addNewUserModelVisiblity, setAddNewUserModelVisiblity] = useState(false);

    const [institutionModelVisablity, setInstitutionModelVisablity] = useState(false);

    const [isLoading, setLoading] = useState(false);


    let data = useSelector(state => state.AllData);


    let reduxDataCrudAction = useSelector(state => state);

    useEffect(() => {

        if (reduxDataCrudAction.crudAction != "" || reduxDataCrudAction.error != null) {

            if (reduxDataCrudAction.crudAction.type == "edit") {

                notify("Data Updated Successfully!");
                setAddNewUserModelVisiblity(false);
                setLoading(false);

            } else if (reduxDataCrudAction.crudAction.type == "delete") {

                // notify(" Deleted '" + reduxDataCrudAction.crudAction.value + "'", "error");
                Swal.fire({
                    title: "Deleted!",
                    text: "\"" + reduxDataCrudAction.crudAction.value + "\" has been deleted.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500
                });


            } else if (reduxDataCrudAction.crudAction.type == "addNew") {

                notify("New Value '" + reduxDataCrudAction.crudAction.value + "' Added!", "success");
                setAddNewUserModelVisiblity(false);
                setLoading(false);

            } else if (reduxDataCrudAction.error != "") {

                notify(reduxDataCrudAction.error, "error");
                setLoading(false);
                dispatch(errorInOperation(null));

            }
            dispatch(setEditedStatus(""));
        }


    }, [reduxDataCrudAction])

    // ----------------------handel hospital model----------------------
    const setHospitalModelVisablityFunction = () => {
        setInstitutionModelVisablity(!institutionModelVisablity);

    }
    // -------------------------handle delete operation ------------------/

    const handleDeleteOperation = (deletedValue, index) => {
        Swal.fire({
            title: "Are you sure you want to delete \"" + deletedValue + "\" ?",
            // text: "All chiled node of \"" + deletedValue + "\" will also be deleted",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: DELETE_USER, key: index, value: deletedValue })

            }
        })

    }

    // -------------------------show new contact model ------------------/
    const [editvalueIndex, setEditValueIndex] = useState("");
    const showAddNewContactModel = (index = "") => {
        // console.log(index)
        setEditValueIndex(index)
        setAddNewUserModelVisiblity(!addNewUserModelVisiblity);
    }

    return (
        <>
            <ToastDefault />
            {institutionModelVisablity && <InstitutionModel setHospitalModelVisablityFunction={setInstitutionModelVisablity} data={data?.policies?.institutions} linkDestination={"/policies/"} loading={{ isLoading, setLoading }} />}

            {addNewUserModelVisiblity && <AddNewUserModel setAddNewUserModelVisiblityFunction={setAddNewUserModelVisiblity} loading={{ isLoading, setLoading }} data={data?.policies?.institutions} usersData={data?.policies?.users} editedData={data?.userinfo[editvalueIndex]} editvalueIndex={editvalueIndex} />}

            {/* {addNewContactModelVisiblity && <AddNewPhoneDirectoryModel hospital={data.policies.documents.uthim.hospitals} showAddNewContactModel={showAddNewContactModel} editedData={data.policies.documents.uthim.entries[editvalueIndex]} editvalueIndex={editvalueIndex} lastIndexofArray={data.policies.documents.uthim.entries.length} />} */}

            {
                (!error) ?

                    <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>

                        <img src={loadingImage} ></img>

                    </div>
                    :
                    <>
                        {(data.userinfo) ?
                            <div className="container disable-text-selection" style={{ "marginTop": "10rem" }}>
                                <div className="row align-items-center">

                                    <div className='col-md-6'>
                                        <h1><b>Roles and Privileges</b></h1>

                                    </div>

                                    <div className='col-md-6'>
                                        <div className="top-right-button-container">
                                            <button type="button" className="btn btn-primary  top-right-button me-1" onClick={() => setHospitalModelVisablityFunction()}>
                                                <FontAwesomeIcon icon={faPlus} />  MANAGE INSTITUTIONS </button>

                                            <button type="button" className="btn btn-primary  top-right-button mr-1" onClick={() => { showAddNewContactModel() }} >
                                                <FontAwesomeIcon icon={faPlus} /> ADD USER</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row rounded">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
                                                <div id="datatableRows_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                                    <table id="datatableRows" className="data-table responsive nowrap dataTable no-footer dtr-inline" style={{ width: "1430px", borderCollapse: "separate", borderSpacing: "0 15px" }}>
                                                        <thead>

                                                            <tr role="row" >

                                                                <th>User Email</th>

                                                                <th >Level Of Training</th>

                                                                <th >Privileges</th>

                                                                <th >Action </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(data.userinfo && Object.keys(data.userinfo).length > 0) ?
                                                                Object.entries(data.userinfo).map(([key, value]) => {
                                                                    return (
                                                                        <tr role='row' key={key} >

                                                                            <td >

                                                                                <p className="" > <b>{value?.email || ""} </b></p>

                                                                            </td>

                                                                            <td >
                                                                                <p><b>{value?.lot || ""} </b></p>

                                                                            </td>
                                                                            <td>
                                                                                <p ><b>{(value?.roles?.content && value?.roles?.policy) ? FULL_CONTENT_ACCESS + " and " + POLICIES_PAGE_ACCESS : (value?.roles?.policy) ? POLICIES_PAGE_ACCESS : FULL_CONTENT_ACCESS} </b></p>

                                                                            </td>
                                                                            <td className="p-0" >

                                                                                <button type="button" className="btn btn-sm btn-primary" onClick={() => { showAddNewContactModel(key) }}  >EDIT</button>

                                                                                <button type="button" className="btn btn-sm btn-danger ms-2 " onClick={() => { handleDeleteOperation(value.email, key) }}>DELETE</button>

                                                                            </td>
                                                                        </tr>
                                                                    )

                                                                })
                                                                :

                                                                < tr >
                                                                    <td colSpan={4}>
                                                                        <div className='display-6'>No Record Found ! </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            :
                            <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>

                                <img src={loadingImage} ></img>

                            </div>
                        }
                    </>
            }
        </>
    );


}
export default RolesAndPermissions;