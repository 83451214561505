import { db } from '../firebaseDb/config/firebase';
import { onValue, ref } from "firebase/database";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeAllData, emptyReduxData } from '../redux/features/fetchAllRecord';
import { GET_DATA_FROM_FIREBASE_DB } from '../constant/constant';
import axios from "axios";
import { useLocation } from 'react-router';


const useFetch = (url, authenticationStatus, setAuthenticationStatus, user, loading, userIdToken) => {

    const [error, setError] = useState(false);
    let location = useLocation();
    const dispatch = useDispatch()
    let key = "";
    let allReduxData = useSelector(state => state.AllData);

    useEffect(() => {

        if (!loading) {
            if (user) {

                let Bearer = localStorage.getItem("JWTToken");
                if (Bearer != null && authenticationStatus && authenticationStatus != -1) {
                    if (location.pathname === "/") {
                        url = "/editor_content";

                        key = "editorContent";

                    } else if (location.pathname === "/roles-and-permissions") {
                        url = "/userinfo";
                        key = "userinfo";
                    }
                    else if (location.pathname === "/Q-Bank") {
                        url = "/q_bank";
                        key = "Q_Bank";
                    }
                    else {
                        url = "/policies";
                        key = "policies";
                    }
                    const query = ref(db, url);

                    if (authenticationStatus && authenticationStatus != -1) {
                        if ((allReduxData.editorContent == undefined && url == "/editor_content") || (allReduxData.policies == undefined && url == "/policies") || (allReduxData.userinfo == undefined && url == "/userinfo") || (allReduxData.Q_Bank == undefined && url == "/q_bank")) {

                            onValue(query, (snapshot) => {
                                const data = snapshot.val();
                                if (snapshot.exists()) {
                                    setError(true)
                                    dispatch(storeAllData({ data: data, key: key }));
                                }

                            });
                            if (location.pathname == "/roles-and-permissions") {
                                onValue(ref(db, "/policies"), (snapshot) => {
                                    const data = snapshot.val();
                                    if (snapshot.exists()) {
                                        dispatch(storeAllData({ data: data, key: "policies" }));
                                    }

                                });
                            }

                        }


                        // return () => {
                        //     dispatch(emptyReduxData());
                        // }
                    }
                }
            }
        }



    }, [authenticationStatus, userIdToken, location]);


    return { error, setError }

}

export default useFetch;


// return allData;