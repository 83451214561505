import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMobileScreenButton, faCommentSms, faTty } from '@fortawesome/free-solid-svg-icons'
import { ContentPage, EditContentElementPage, ListPage, DELETE_SECTION } from '../../constant/constant';
import TinyEditorSection from './TinyEditorSection';
import { useState } from 'react';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import loadingImage from '../../assets/images/loading2.gif'
import { useNavigate, useLocation } from "react-router-dom";
import HospitalModel from './HospitalModel';
import Swal from 'sweetalert2';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import { setEditedStatus, deleteData, errorInOperation } from '../../redux/features/fetchAllRecord';
import AddNewPhoneDirectoryModel from './AddNewPhoneDirectoryModel';
import GroupHandleModel from './GroupHandleModel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const PhoneDirectory = ({ error, userId }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [addNewContactModelVisiblity, setAddNewContactModelVisiblity] = useState(false);

    const [hospitalModelVisablity, setHospitalModelVisablity] = useState(false);
    const [groupModelVisablity, setGroupModelVisablity] = useState(false);
    const [loading, setLoading] = useState(false);

    let data = useSelector(state => state.AllData);

    let [userKey, setUserKey] = useState("");


    let reduxDataCrudAction = useSelector(state => state);

    useEffect(() => {

        if (reduxDataCrudAction.crudAction != "" || reduxDataCrudAction.error != null) {

            if (reduxDataCrudAction.crudAction.type == "edit") {

                notify("Data Updated Successfully!");
                setAddNewContactModelVisiblity(false);

            } else if (reduxDataCrudAction.crudAction.type == "delete") {

                // notify(" Deleted '" + reduxDataCrudAction.crudAction.value + "'", "error");
                Swal.fire({
                    title: "Deleted!",
                    text: "\"" + reduxDataCrudAction.crudAction.value + "\" has been deleted.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500
                });


            } else if (reduxDataCrudAction.crudAction.type == "addNew") {

                notify("New Value '" + reduxDataCrudAction.crudAction.value + "' Added!", "success");
                setAddNewContactModelVisiblity(false);

            } else if (reduxDataCrudAction.error != "") {

                notify(reduxDataCrudAction.error, "error");
                dispatch(errorInOperation(null));

            }
            dispatch(setEditedStatus(""));
            setLoading(false);
        }


    }, [reduxDataCrudAction])

    // ----------------------handel hospital model----------------------
    const setHospitalModelVisablityFunction = () => {
        setHospitalModelVisablity(!hospitalModelVisablity);

    }
    // ----------------------handel group model----------------------
    const setGroupModelVisablityFunction = () => {
        setGroupModelVisablity(!groupModelVisablity);

    }
    // -------------------------handle delete operation ------------------/

    const handleDeleteOperation = (deletedValue, index) => {
        Swal.fire({
            title: "Are you sure you want to delete \"" + deletedValue + "\" ?",
            // text: "All chiled node of \"" + deletedValue + "\" will also be deleted",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: DELETE_SECTION, key: '/policies/documents/' + userKey + '/entries/' + index, value: deletedValue })

            }
        })

    }

    // -------------------------show new contact model ------------------/
    const [editvalueIndex, setEditValueIndex] = useState("");
    const showAddNewContactModel = (index = "") => {
        setEditValueIndex(index)
        setAddNewContactModelVisiblity(!addNewContactModelVisiblity);
    }
    // ------------------------------------------------//

    useEffect(() => {

        if (data && Object.keys(data).length > 0) {
            let UserKey = (data?.policies?.users[userId.uid] != null &&
                Object.keys(data.policies.users[userId.uid].amionLogin).length > 0) ? Object.keys(data.policies.users[userId.uid]?.amionLogin)[0] : ""
            if (UserKey != "") {
                let documentKey = (data?.policies?.institutions.length > 0) ? data.policies.institutions.find(element => element?.amionLogin == UserKey) : UserKey;
                setUserKey((documentKey?.documentKey) ? documentKey.documentKey : UserKey)
            }
        }


    }, [data])


    return (
        <>
            <ToastDefault />
            {hospitalModelVisablity && <HospitalModel setHospitalModelVisablityFunction={setHospitalModelVisablityFunction} data={data?.policies?.documents?.[userKey]?.hospitals} linkDestination={"/policies/documents/" + userKey + "/hospitals/"} type="policy" />}

            {groupModelVisablity && <GroupHandleModel setHospitalModelVisablityFunction={setGroupModelVisablityFunction} data={data?.policies?.documents?.[userKey]?.groups} linkDestination={"/policies/documents/" + userKey + "/groups/"} type="policy" loading={loading} setLoading={setLoading} phoneDirectoryData={data?.policies?.documents?.[userKey]?.entries} />}


            {addNewContactModelVisiblity && <AddNewPhoneDirectoryModel hospital={data?.policies?.documents?.[userKey]?.hospitals}
                groupData={data?.policies?.documents?.[userKey]?.groups} showAddNewContactModel={showAddNewContactModel} editedData={data?.policies?.documents?.[userKey]?.entries?.[editvalueIndex]} editvalueIndex={editvalueIndex} lastIndexofArray={data?.policies?.documents?.[userKey]?.entries?.length} userKey={userKey} type="policy" loading={loading} setLoading={setLoading} />}

            {
                (!error) ?

                    <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>

                        <img src={loadingImage} ></img>

                    </div>
                    :
                    <>
                        {userKey != "" && (data?.policies?.documents?.[userKey]) ?

                            <div className="container disable-text-selection" style={{ "marginTop": "10rem" }}>
                                <div className="row align-items-center">

                                    <div className='col-md-6'>
                                        <h1><b>Phone Directory</b></h1>

                                    </div>

                                    <div className='col-md-6'>
                                        <div className="top-right-button-container">
                                            <button type="button" className="btn btn-primary  top-right-button me-1" onClick={() => setGroupModelVisablityFunction()}>
                                                <FontAwesomeIcon icon={faPlus} />  MANAGE GROUPING </button>

                                            <button type="button" className="btn btn-primary  top-right-button me-1" onClick={() => setHospitalModelVisablityFunction()}>
                                                <FontAwesomeIcon icon={faPlus} />  MANAGE HOSPITAL </button>

                                            <button type="button" className="btn btn-primary  top-right-button mr-1" onClick={() => { showAddNewContactModel() }} >
                                                <FontAwesomeIcon icon={faPlus} /> ADD CONTACT</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row rounded">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
                                                <div id="datatableRows_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                                    <Tabs
                                                        defaultActiveKey="all"
                                                        id="fill-tab-example"
                                                        className="mt-3"
                                                        fill >
                                                        <Tab eventKey="all" title="Others" >
                                                            <table id="datatableRows" className="data-table responsive nowrap dataTable no-footer dtr-inline" style={{ width: "1430px", borderCollapse: "separate", borderSpacing: "0 15px" }}>
                                                                <thead>

                                                                    <tr role="row" >

                                                                        <th>Name</th>

                                                                        <th >Hospital</th>

                                                                        <th >Number</th>

                                                                        <th >Contact Mode</th>

                                                                        <th >Notes</th>

                                                                        <th >Action</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(data?.policies?.documents?.[userKey]?.entries &&
                                                                        data.policies.documents[userKey]?.entries.filter(entry => entry?.group == undefined ||
                                                                            entry?.group == "-1" || entry?.group == "Others").length > 0
                                                                    )
                                                                        ? data.policies.documents[userKey]?.entries.filter(entry => entry?.group == undefined ||
                                                                            entry?.group == "-1" || entry?.group == "Others").sort((a, b) => a.name.localeCompare(b.name)).map((key, index) => {

                                                                                return (
                                                                                    <tr role='row' key={index} >

                                                                                        <td >
                                                                                            <p className="" > <b>{key?.name} </b></p>

                                                                                        </td>

                                                                                        <td >

                                                                                            <p >{key?.hospital}</p>

                                                                                        </td>
                                                                                        <td>

                                                                                            <p >{key?.number}</p>

                                                                                        </td>
                                                                                        <td>

                                                                                            {/* <p >{key?.contact_type}</p> */}
                                                                                            <FontAwesomeIcon size='2xl' icon={faCommentSms} className={`mx-4 ${(key?.contact_type == "Text" || key?.contact_type == "Both") ? "text-success" : "text-black-50"}`} />
                                                                                            <FontAwesomeIcon size='2xl' className={`${(key?.contact_type == "Call" || key?.contact_type == "Both") ? "text-success" : "text-black-50"}`} icon={faTty} />

                                                                                        </td>
                                                                                        <td>

                                                                                            <p >{key?.notes}</p>


                                                                                        </td>
                                                                                        <td className="p-0" >
                                                                                            <button type="button" className="btn btn-sm btn-primary" onClick={() => { showAddNewContactModel(data.policies.documents[userKey]?.entries.findIndex(entry => (entry?.name == key?.name && entry?.number == key?.number))) }}>EDIT</button>

                                                                                            <button type="button" className="btn btn-sm btn-danger ms-2 " onClick={() => { handleDeleteOperation(key.name, (data.policies.documents[userKey]?.entries.findIndex(entry => (entry?.name == key?.name && entry?.number == key?.number)))) }}>DELETE</button>

                                                                                        </td>
                                                                                    </tr>
                                                                                )

                                                                            })
                                                                        :
                                                                        <tr>
                                                                            <td colSpan={6} className='text-center fw-bold text-danger fs-5'>
                                                                                No Record Found
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Tab>
                                                        {(data?.policies?.documents?.[userKey]?.groups && Object.keys(data?.policies?.documents?.[userKey]?.groups).length > 0)
                                                            ?
                                                            Object.values(data?.policies?.documents?.[userKey]?.groups).toSorted((a, b) => a?.orderInPage - b?.orderInPage).map((item, Outerindex) => {
                                                                return (
                                                                    <Tab eventKey={Outerindex} title={item?.name} >
                                                                        <table id="datatableRows" className="data-table responsive nowrap dataTable no-footer dtr-inline" style={{ width: "1430px", borderCollapse: "separate", borderSpacing: "0 15px" }}>
                                                                            <thead>

                                                                                <tr role="row" >
                                                                                  
                                                                                    <th>Name</th>

                                                                                    <th >Hospital</th>

                                                                                    <th >Number</th>

                                                                                    <th >Contact Mode</th>

                                                                                    <th >Notes</th>

                                                                                    <th >Action</th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {(
                                                                                    data?.policies?.documents?.[userKey]?.entries
                                                                                    &&
                                                                                    data.policies.documents[userKey].entries.filter(entry => (entry?.group == item?.key) || (entry?.group == item?.name)).length > 0)
                                                                                    ? data.policies.documents[userKey]?.entries.filter(entry => (entry?.group == item?.key) || (entry?.group == item?.name)).sort((a, b) => a.name.localeCompare(b.name)).map((key, index) => {
                                                                                        return (
                                                                                            <tr role='row' key={index} >
                                                                                    
                                                                                                <td >

                                                                                                    <p className="" > <b>{key?.name} </b></p>

                                                                                                </td>

                                                                                                <td >

                                                                                                    <p >{key?.hospital}</p>

                                                                                                </td>
                                                                                                <td>

                                                                                                    <p >{key?.number}</p>

                                                                                                </td>
                                                                                                <td>

                                                                                                    {/* <p >{key?.contact_type}</p> */}
                                                                                                    <FontAwesomeIcon size='2xl' icon={faCommentSms} className={`mx-4 ${(key?.contact_type == "Text" || key?.contact_type == "Both") ? "text-success" : "text-black-50"}`} />
                                                                                                    <FontAwesomeIcon size='2xl' className={`${(key?.contact_type == "Call" || key?.contact_type == "Both") ? "text-success" : "text-black-50"}`} icon={faTty} />

                                                                                                </td>
                                                                                                <td>

                                                                                                    <p >{key?.notes}</p>


                                                                                                </td>
                                                                                                <td className="p-0" >

                                                                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => { showAddNewContactModel(data.policies.documents[userKey]?.entries.findIndex(entry => (entry?.name == key?.name && entry?.number == key?.number))) }}>EDIT</button>

                                                                                                    <button type="button" className="btn btn-sm btn-danger ms-2 " onClick={() => { handleDeleteOperation(key.name, (data.policies.documents[userKey]?.entries.findIndex(entry => (entry?.name == key?.name && entry?.number == key?.number)))) }}>DELETE</button>

                                                                                                </td>
                                                                                            </tr>
                                                                                        )

                                                                                    })
                                                                                    :
                                                                                    <tr>
                                                                                        <td colSpan={6} className='text-center fw-bold text-danger fs-5'>
                                                                                            No Record Found
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </Tab>
                                                                )
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </Tabs>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className=" d-flex justify-content-center align-items-center" style={{ "height": "90vh" }}>
                                {

                                    (userKey != "" && data?.policies?.documents?.[userKey] == undefined) ?

                                        <div className='text-center fw-bold text-danger fs-5'>
                                            No Record Found
                                        </div>

                                        :

                                        <img src={loadingImage} ></img>
                                }



                            </div>
                        }

                    </>
            }
        </>
    );


}
export default PhoneDirectory