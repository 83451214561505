import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { faPenToSquare, faTrash, faXmark, faCheck, faListOl } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';
import { notify } from '../toasts/ToastDefault';
import Button from 'react-bootstrap/Button';
import { ADD_NEW_AT_ROOT, DELETE_SECTION, EDIT_TEXT_AND_SHORT_TEXT, GROUP_PAGE, SET_ORDER_PAGE } from '../../constant/constant';
import Swal from 'sweetalert2';
import OrderListComponent from './OrderListComponet';

const GroupHandleModel = ({ setHospitalModelVisablityFunction, data, linkDestination, loading, setLoading, phoneDirectoryData }) => {

    // handle new hospital form submit 
    const dispatch = useDispatch()



    // on add new form submit 
    const [addNew, setAddNewInput] = useState({
        name: "",
    });

    // for reorder data manage state 
    const [reorderData, setReorderData] = useState([]);

    // reorderdiplayData
    const [reorderDisplayData, setReorderDisplayData] = useState([]);

    const [orderListView, setOrderListView] = useState(false);

    let name, value;
    const getRecord = (event) => {

        name = event.target.name;
        value = event.target.value;
        setAddNewInput({ ...addNew, [name]: value });
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (addNew.name.trim() != "") {
            setLoading(true);
            dispatch({ type: ADD_NEW_AT_ROOT, linkDestination: linkDestination, data: addNew.name, navPush: GROUP_PAGE, contentType: "Group", orderInPage: data ? Object.keys(data).length : 0 });
            setAddNewInput({
                name: "",
            });
            e.target.reset()

        } else {

            notify("Please Add New Group Name !", "error");
        }


    }

    // -----------------handle delete event----------------//
    const handleDeleteEvent = (deletedValue, index) => {
        if ((phoneDirectoryData == undefined) || Object.values(phoneDirectoryData).filter(el => el?.group === deletedValue?.key).length <= 0) {
            Swal.fire({
                title: "Are you sure you want to delete \"" + deletedValue?.name + "\" ?",
                // text: "All chiled node of \"" + deletedValue + "\" will also be deleted",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({ type: DELETE_SECTION, key: deletedValue.linkDestination, value: deletedValue?.name });

                }
            });
        } else {
            notify(deletedValue?.name + " must be empty before deleting it!", "error");
        }

    }
    // handle toggale edit event 
    const [editValue, setEditValue] = useState({});
    const handleToggleEditEvent = (index, editValue = "") => {

        setAddNewInput({ name: editValue?.name })
        setEditValue({ index: index })

    }

    function edit({ propt, index }) {

        if (addNew.name.trim() != "" && index) {
            // console.log(index)
            dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { "name": addNew.name }, key: index, propt });

            setAddNewInput({
                name: "",
            });
            setEditValue({});

        } else {

            notify("Please Enter Hospital Name !", "error");
        }

    }

    // show reorder diplay data
    function handleReorderListDisplayData(data) {
        setReorderDisplayData(data);
        setOrderListView(!orderListView);
    }

    // save reorder list to firebase and redux state 
    function saveReorderList() {

        if (reorderData.length > 0) {

            dispatch({ type: SET_ORDER_PAGE, data: reorderData });

        }
        setOrderListView(false);
    }
    /////////// close editing section ///////////////

    return (
        <>
            {/* <ToastDefault /> */}
            <div className="modal modal-right show d-block " id="hospitalModel" tabIndex="-1" role="dialog" aria-labelledby="hospitalModel" style={{ "paddingRight": "17px", "background": "rgba(0,0,0,0.6)" }} aria-modal="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold ">Groups</h5>
                            <button type="button" className="btn-close" onClick={() => setHospitalModelVisablityFunction()}></button>
                        </div>
                        <div className="modal-body">

                            <form onSubmit={(e) => { handleFormSubmit(e) }}>
                                <Form.Group className="mt-3" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Add New Group" onChange={(e) => getRecord(e)} name='name' />
                                </Form.Group>
                                <div className="form-group mt-3">
                                    <Button variant="primary" type="submit" disabled={loading}>
                                        {/* {loading.isLoading ? 'Submiting…' : 'Submit'} */}
                                        Save Group
                                    </Button>
                                </div>
                            </form>

                            <div>
                                <div className="mt-2">
                                    <div className="">
                                        <div className="">
                                            <div className='d-flex justify-content-between my-5'>
                                                <h2 className="card-title text-center fw-bold"> {orderListView ? "Reorder" : "List of"} Groups</h2>
                                                {
                                                    (orderListView)
                                                        ?
                                                        <button type="button" className="btn btn-success btn-sm " onClick={() => saveReorderList()}>
                                                            <FontAwesomeIcon icon={faListOl} /> SAVE & RETURN</button>
                                                        :
                                                        <button type="button" className="btn btn-success btn-sm " onClick={() => handleReorderListDisplayData(data)} >
                                                            <FontAwesomeIcon icon={faListOl} /> REORDER LIST</button>
                                                }

                                            </div>

                                            <hr />
                                            {
                                                (orderListView)

                                                    ?
                                                    <OrderListComponent reduxData={reorderDisplayData} setReorderData={setReorderData} type={"group"} />

                                                    :
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(data && Object.keys(data).length > 0) ? Object.values(data).toSorted((a, b) => a?.orderInPage - b?.orderInPage).map((item, index) => {

                                                                return (
                                                                    <>
                                                                        <tr key={index} >

                                                                            <td style={{ wordBreak: "break-word" }}>
                                                                                {(editValue.index == index) ?
                                                                                    <>
                                                                                        <input type="text" style={{ width: "10rem" }} className='fw-bold' name='name' onChange={(e) => getRecord(e)} value={addNew.name} />

                                                                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => setEditValue({})} className='ms-3 text-primary' icon={faXmark} />

                                                                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} className='ms-3 text-primary' onClick={() => edit({ propt: ["name"], index: item?.linkDestination })} icon={faCheck} />
                                                                                    </>
                                                                                    :
                                                                                    <b>  {item?.name || "No name"}</b>
                                                                                }
                                                                            </td>
                                                                            <td>

                                                                                <FontAwesomeIcon className='text-success mx-3' size='xl' onClick={() => handleToggleEditEvent(index, item)} icon={faPenToSquare} />

                                                                                <FontAwesomeIcon className='text-danger' size='xl' onClick={() => handleDeleteEvent(item, index)} icon={faTrash} />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                                :
                                                                <tr>
                                                                    <td colSpan={2} className='text-center fw-bold text-danger fs-5'>
                                                                        No Record Found
                                                                    </td>
                                                                </tr>

                                                            }
                                                        </tbody >
                                                    </table>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default GroupHandleModel
