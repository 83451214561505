import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLandmark, faNewspaper, faBlenderPhone, faPhotoFilm, faLock } from '@fortawesome/free-solid-svg-icons';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import $ from 'jquery';


const SideNav = ({ userPermission }) => {
    const location = useLocation();

    var curentUrl = location.pathname;

    useEffect(() => {
        $('#sideBar li ').each(function () {
            var atthis = $(this);
            var atthisChildren = $(this).children('a');
            if (atthisChildren.attr('href') != undefined) {
                if (curentUrl == atthisChildren.attr('href')) {
                    atthis.addClass('active')
                } else {
                    atthis.removeClass('active');
                }
            }
        });

    }, [curentUrl])
    return <>
        <div className="menu" style={{ "opacity": "1" }}>
            <div className="main-menu  default-transition">
                <div className="scroll ps">
                    <ul className="list-unstyled" id='sideBar' >
                        {(Object.keys(userPermission).length > 0 && userPermission.roles.content) && <li key={"sidebar_1"} className="">
                            <Link to={'/'}>
                                <FontAwesomeIcon icon={faNewspaper} size='2xl' />
                                <span className='mt-3'>
                                    Editor
                                </span>
                            </Link>
                        </li>}
                        {(Object.keys(userPermission).length > 0 && userPermission.roles.content) && <li key={"sidebar_2"} className="">
                            <Link to={'/Q-Bank'}>
                                <FontAwesomeIcon icon={faTeamspeak} size='2xl' />
                                <span className='mt-3'>
                                    Q Bank
                                </span>
                            </Link>
                        </li>}
                        {(Object.keys(userPermission).length > 0 && userPermission.roles.policy) && <li key={"sidebar_3"} className="">
                            <Link to={'/policy'}>
                                <FontAwesomeIcon icon={faLandmark} size='2xl' />
                                <span className='mt-3'>
                                    Policy
                                </span>
                            </Link>
                        </li>}

                        {(Object.keys(userPermission).length > 0 && userPermission.roles.policy) && <li key={"sidebar_4"} className="">
                            <Link to={'/phone-direactory'}>
                                <FontAwesomeIcon icon={faBlenderPhone} size='2xl' />
                                <span className='mt-3'>
                                    Phone Directory
                                </span>
                            </Link>
                        </li>}

                        {/* {(Object.keys(userPermission).length > 0 && userPermission.roles.superAdmin) && <li key={"sidebar_5"} >
                            <Link to={'https://photos.google.com/?pageId=none&pli=1'} target='_blank'>
                                <FontAwesomeIcon icon={faPhotoFilm} size='2xl' />
                                <span className='mt-3'>
                                    Photos
                                </span>
                            </Link>
                        </li>} */}
                        {(Object.keys(userPermission).length > 0 && userPermission.roles.superAdmin) && <li key={"sidebar_6"} className="">
                            <Link to={'/roles-and-permissions'}>
                                <FontAwesomeIcon icon={faLock} size='2xl' />
                                <span className='mt-3'>
                                    Roles And Permissions
                                </span>
                            </Link>
                        </li>}
                    </ul>
                    <div className="ps__rail-x" style={{ "left": "0px", "bottom": "0px" }}>
                        <div className="ps__thumb-x" tabIndex="0" style={{ "left": "0px", "width": "0px" }}>
                        </div>
                    </div>
                    <div className="ps__rail-y" style={{ "top": "0px", "right": "0px" }}>
                        <div className="ps__thumb-y" tabIndex="0" style={{ "top": "0px", "height": "0px" }}>
                        </div>
                    </div>
                </div>
            </div>
            {/* sub menu side bar  */}
            {/* <div className="sub-menu default-transition">
                <div className="scroll ps">
                    <ul className="list-unstyled" data-link="dashboard" style={{ "display": "block" }}>
                        <li className="active">
                            <a >
                                <FontAwesomeIcon icon={faRocket} size='xl' /> <span className="d-inline-block">Default</span>
                            </a>
                        </li>
                        <li>
                            <a >
                                <FontAwesomeIcon icon={faChartPie} size='xl' /><span className="d-inline-block ms-2">Analytics</span>
                            </a>
                        </li>
                        <li>
                            <a >
                                <FontAwesomeIcon icon={faBasketShopping} size='xl' /><span className="d-inline-block ms-2">Ecommerce</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <FontAwesomeIcon icon={faFile} size='xl' /> <span className="d-inline-block ms-2">Content</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> */}
        </div>
    </>
}

export default SideNav;