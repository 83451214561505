import React, { useEffect, useState } from 'react'
import ToastDefault, { notify } from '../toasts/ToastDefault';
import { useDispatch, useSelector } from 'react-redux';
import { FORGOT_PASSWORD_OTP } from '../../constant/constant';
import Otp_Verify_Model from '../subComponets/Otp_Verify_Model';
import { clearAll, clearStep } from '../../redux/features/fetchAllRecord';
import { Link, useLocation, useNavigate } from 'react-router-dom';
function ForgotPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState(location?.state?.email || '');
    const [loading, setLoading] = useState(false);
    const [updatePass, setUpdatePass] = useState({
        password: '',
        confirmPassword: ''
    });
    const { forgotPasswordOtpSent, varifyOtp, error, userPasswordUpdateStatus, forgotPasswordOtpSentData } = useSelector((state) => state);
    function isValidEmail(email) {
        // Basic email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const onFinish = (e) => {
        e.preventDefault();
        if (!isValidEmail(email)) {
            notify("Invalid email address", "error");
        } else {
            dispatch({ type: FORGOT_PASSWORD_OTP, email: email });
            setLoading(true);
        }

    }

    useEffect(() => {
        if (error !== "") {
            notify(error, "error");
            dispatch(clearStep({ key: "error", value: "" }));
        }
        // else if (forgotPasswordOtpSent === "success" && varifyOtp === "" && userPasswordUpdateStatus === "") {
        //     notify("Otp sent successfully!", "success");
        // }
        // else if (varifyOtp === "success" && userPasswordUpdateStatus === "") {
        //     notify("Otp verified successfully!", "success");
        // }
        else if (userPasswordUpdateStatus === "success") {
            notify("Password updated successfully ! Please login.", "success");
            setTimeout(() => {
                navigate("/login");
                dispatch(clearAll());
            }, 2000);
        }
        setLoading(false);

    }, [forgotPasswordOtpSent, varifyOtp, error, userPasswordUpdateStatus]);


    const onFinishUpdatePassword = (e) => {
        e.preventDefault();
        if (checkPassword()) {
            dispatch({
                type: "UPDATE_PASSWORD", email: email, password: updatePass.password, userId: forgotPasswordOtpSentData?.userId || "",
                time: forgotPasswordOtpSentData?.time || ""
            });
            setUpdatePass({
                password: '',
                confirmPassword: ''
            });
            e.target.reset();
            setLoading(true);

        }
    }

    const checkPassword = () => {

        let newPassword = updatePass.password;
        let confirmPassword = updatePass.confirmPassword;

        if (newPassword.trim() == "" || confirmPassword.trim() == "") {
            notify("All fields are required", "error");
            return false;
        }
        else if (newPassword.length < 6 || confirmPassword.length < 6) {
            notify("Password must be at least 6 characters", "error");
            return false;
        } else if (newPassword != confirmPassword) {
            notify("Password does not match", "error");
            return false;

        } else if (newPassword == confirmPassword) {
            return true;
        }
    };

    function handleLinkClick() {
        dispatch(clearAll());
    }
    return (
        <>
            <ToastDefault />
            <div className='fixed-background'>
                <div className='blurEffect'></div>
            </div>
            <main className="default-transition" >
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">

                            <div className="card auth-card">
                                <div className="position-relative image-side ">
                                </div>
                                <div className="form-side">

                                    <span className="logo-single"></span>
                                    {
                                        (varifyOtp === "success") ?
                                            <>
                                                <h1 className="mb-4">Update Password</h1>
                                                <form onSubmit={(e) => onFinishUpdatePassword(e)}>
                                                    <div className="form-group">
                                                        <label htmlFor="password"><strong>Password</strong> </label>
                                                        <input type="password" name="password" className="form-control" placeholder='Enter password ' onChange={(e) => setUpdatePass({ ...updatePass, password: e.target.value })}
                                                            value={updatePass?.password} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password"><strong>Confirm Password</strong> </label>
                                                        <input type="password" name="confirmPassword"
                                                            className="form-control" placeholder='Enter confirm password' onChange={(e) => setUpdatePass({ ...updatePass, confirmPassword: e.target.value })}
                                                            value={updatePass?.confirmPassword} />
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <button type="submit" className="btn btn-primary mt-2" disabled={loading}>Update</button>
                                                        {userPasswordUpdateStatus === "success" && <Link onClick={() => handleLinkClick()} to="/login" > <span className='fw-bold'> Login here </span></Link>}
                                                    </div>
                                                </form>
                                            </>
                                            :
                                            (forgotPasswordOtpSent == "success")
                                                ?
                                                <Otp_Verify_Model email={email} loading={loading} setLoading={setLoading} />
                                                :
                                                <>
                                                    <h1 className="mb-4">Forgot Password</h1>
                                                    <form onSubmit={(e) => onFinish(e)}>
                                                        <div className="form-group">
                                                            <label htmlFor="UserEmail"><strong>Email address</strong> </label>
                                                            <input type="email" name="email" className="form-control" placeholder='Enter email address'
                                                                id="UserEmail" onChange={(e) => setEmail(e.target.value)} value={email} aria-describedby="emailHelp" />
                                                        </div>
                                                        <button type="submit" className="btn btn-primary mt-2" disabled={loading}>Send OTP</button>
                                                    </form>
                                                </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ForgotPassword