import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ToastDefault, { notify } from '../toasts/ToastDefault';
import { signIn, signOut } from '../../firebaseDb/auth';
import { useDispatch } from 'react-redux';
import { GET_DATA_FROM_FIREBASE_DB } from '../../constant/constant';
import axios from "axios";




const Login = ({ setAuthenticationStatus, authenticationStatus, userPermission, setUserPermission, user }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [values, setValues] = useState({
        email: "",
        pass: "",
    });
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    // on change function to set value 
    let name, value;
    function getRecord(event) {
        name = event.target.name;
        value = event.target.value;
        setValues({ ...values, [name]: value });

    }
    // handle login form status 
    async function handleLoginFormSubmitStatus(e) {
        e.preventDefault();
        if (values.email.trim() !== "" && values.pass.trim() !== "") {
            const email = values.email;
            const pass = values.pass;
            setSubmitButtonDisabled(true);
            try {
                let res = await signIn({ email, pass });
                if (res.error) {
                    notify("Provide Valid Credentials!", "error");
                    return;
                } else if (!res.error && res.errorCode == 200) {
                    if (res.userInfo.roles.appuser && (!res.userInfo.roles.content) && (!res.userInfo.roles.policy)) {
                        notify("You are not authorized to access this Panel !", "error");
                        signOut();
                        return;
                    }
                    else if (res.userInfo.roles.policy && (!res.userInfo.roles.content)) {
                        navigate("/policy");
                    } else {
                        navigate("/");
                    }
                    setUserPermission(res.userInfo);
                    setAuthenticationStatus(true);
                    return;
                }


            } catch (err) {
                setAuthenticationStatus(false);
                notify("There was an error in login !", "error");
                throw new Error(err);

            } finally {
                setSubmitButtonDisabled(false);
            }

        } else {
            notify("Provide Valid Credentials !", "error");
        }

    }

    useEffect(() => {
        (async () => {

            if (user && localStorage.getItem('JWTToken') != null) {
                const res = await axios.post(GET_DATA_FROM_FIREBASE_DB, { path: "/userinfo/" + user.uid }, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('JWTToken').trim()}`,
                    }
                })
                if (res.data.data == null) {
                    notify("No Record Found !", "error");
                    signOut();
                    localStorage.clear();
                    setAuthenticationStatus(false);
                    setUserPermission({});
                }
                else if (!res.data.error) {
                    if (res.data.data.roles.appuser && (!res.data.data.roles.content) && (!res.data.data.roles.policy)) {
                        notify("You are not authorized to access this Panel !", "error");
                        setAuthenticationStatus(false);
                        setUserPermission({});
                        signOut();
                        localStorage.clear();
                        return;
                    }
                    else if (res.data.data.roles.policy && (!res.data.data.roles.content)) {
                        navigate("/policy");
                        setAuthenticationStatus(true);
                        setUserPermission(res.data.data)
                    } else {
                        navigate("/")
                        setAuthenticationStatus(true);
                        setUserPermission(res.data.data)
                    }

                } else {
                    notify("You are not authorized to access this Panel !", "error");
                    setAuthenticationStatus(false);
                    setUserPermission({});
                    localStorage.clear();
                }

            }

        })();

    }, [user])

    return (
        <>
            <ToastDefault />
            <div className='fixed-background'>
                <div className='blurEffect'></div>
            </div>
            <main className="default-transition" >
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">

                            <div className="card auth-card">

                                <div className='position-absolute text-success r-0 p-4 fw-bold d '>
                                    {(localStorage.getItem('JWTToken') && Object.keys(userPermission).length <= 0) ? "Geting data from server..." : "Please Login !"}
                                </div>

                                <div className="position-relative image-side ">
                                </div>
                                <div className="form-side">

                                    <span className="logo-single"></span>

                                    <h1 className="mb-4">Login</h1>
                                    <form onSubmit={(e) => handleLoginFormSubmitStatus(e)}>
                                        <div className="form-group">
                                            <label htmlFor="UserEmail"><strong>Email address</strong> </label>
                                            <input type="email" name="email" className="form-control" placeholder='Enter email address' id="UserEmail" onChange={(e) => getRecord(e)} aria-describedby="emailHelp" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="UserPasword"><strong>Password</strong> </label>
                                            <input type="password" className="form-control" name="pass" onChange={(e) => getRecord(e)} placeholder='Enter password' id="UserPasword" />
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>

                                            <button type="submit" disabled={submitButtonDisabled} className="btn btn-primary mt-2">{submitButtonDisabled ? "Logging In..." : "Login"} </button>

                                            <span onClick={() => navigate("/forgotpassword", { state: { email: values.email } })} style={{ cursor: 'pointer' }} ><span className='fw-bold'>Forgot password?</span></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Login
