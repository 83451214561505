
import { text } from '@fortawesome/fontawesome-svg-core';
import { db } from '../config/firebase';
// import { db } from './firebase';
import { onValue, ref, set, update, remove, push, child } from "firebase/database";
import { storage } from '../../firebaseDb/config/firebase';
import data11 from '../../JSON File/indigo_db_fresh.json';
import { collection, addDoc, doc, setDoc } from 'firebase/firestore';
import { consoleFxn } from '../../console/console';
import { ListPage, ContentPage, EditContentElementPage, ADD_NEW_HOSPITAL, ADD_NEW_CONTACT, ADD_NEW_INSTITUTE, DELETE_DATA_FROM_FIREBASE_DB, SET_DATA_TO_FIREBASE_DB, SEND_NOTIFICATION_TO_APP_URL, UPDATE_DATA_IN_FIREBASE_DB, ROOT_EDITOR_CONTENT_KEY, NOTIFICATION_ADDED, NOTIFICATION_DELETED, NOTIFICATION_UPDATED, NOTIFICATION_ORDERINPAGE, QBankCategory, QBankQuestion, GROUP_PAGE } from '../../constant/constant';
import axios from "axios";
import { captureException } from '@sentry/react';

export const firebaseDbRoot = "/";

export const add_new_data = async (data, linkDestination) => {

  let error = false;

  let notificationPath = (linkDestination != "/");

  const newKey = push(child(ref(db), linkDestination)).key;

  let pathToAddData = (linkDestination != "/") ? linkDestination + newKey + "/" : linkDestination + newKey + "/";

  let setData = "";

  if (data.navPush == ListPage) {

    setData =
    {
      gridBackground: "",
      gridImg: "",
      key: newKey,
      linkDestination: pathToAddData,
      linkType: 1,
      navPush: data.navPush,
      shortText: data.shortText,
      text: data.text,
      orderInPage: data.orderInPage,
    };
  } else if (data.navPush == ContentPage) {

    setData = {
      gridImg: "",
      itemChanged: "",
      key: newKey,
      keywords: "",
      linkDestination: pathToAddData,
      linkType: 1,
      navPush: data.navPush,
      orderInPage: data.orderInPage,
      text: data.text,

    }
  }
  else if (data.navPush == EditContentElementPage) {

    setData = {
      accordionIsCollapsed: true,
      collapsibleImage: data.collapsibleImage,
      body: data.editorBody,
      elementType: data.elementType,
      imgURL: data.imgUrl,
      itemChanged: false,
      key: newKey,
      navPush: data.navPush,
      title: data.text,
      orderInPage: data.orderInPage,
    }

  }
  else if (data.navPush == ADD_NEW_HOSPITAL) {
    setData = data.data
    pathToAddData = data.linkDestination

  } else if (data.navPush == ADD_NEW_CONTACT) {

    setData = data.data
    pathToAddData = data.linkDestination

  } else if (data.navPush == ADD_NEW_INSTITUTE) {
    setData = data.data
    pathToAddData = data.linkDestination
  } else if (data.navPush == QBankCategory) {
    setData =
    {
      key: newKey,
      linkDestination: pathToAddData,
      navPush: data.navPush,
      shortText: data.shortText,
      text: data.text,
      orderInPage: data.orderInPage,
    };
  } else if (data.navPush == QBankQuestion) {

    setData = {
      key: newKey,
      linkDestination: pathToAddData,
      navPush: data.navPush,
      ...data.values,
      questionChoices: data.questionChoices
    }


  } else if (data.navPush == GROUP_PAGE) {

    setData = {
      key: newKey,
      linkDestination: pathToAddData,
      navPush: data.navPush,
      name: data.data,
      orderInPage: data.orderInPage,
    }

  }

  if (data.contentType == "editor") {
    pathToAddData = ROOT_EDITOR_CONTENT_KEY + pathToAddData
    //this for add new content inside added new key 
  }
  try {
    let setDataRes = await axios.post(SET_DATA_TO_FIREBASE_DB, { pathToAddData: pathToAddData, setData: setData }, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("JWTToken").trim()}`,
      }
    });
    if (!setDataRes.data.error) {
      if (data.contentType == "editor" && notificationPath) {
        axios.post(SEND_NOTIFICATION_TO_APP_URL, { data: setData, path: linkDestination + newKey + "/", action: NOTIFICATION_ADDED });
      }
      error = true;
    } else {

      error = false;
    }

  } catch (error) {

    error = false;
    captureException(error, "error");

  }
  return error
}
export const update_data = async (data, key, editPropt, allData) => {
  const updates = {};
  let error = false;

  try {

    data = Object.assign(data);

    editPropt.forEach(element => {

      updates[(allData.contentType == "editor") ? ROOT_EDITOR_CONTENT_KEY + key + element : key + element] = data[element];

    });
    
    let updateRes = await axios.post(UPDATE_DATA_IN_FIREBASE_DB, { AllData: updates }, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("JWTToken").trim()}`,
      }
    });
    if (!updateRes.data.error) {
      if (Object.keys(updates).length > 0 && (allData.contentType == "editor")) {

        axios.post(SEND_NOTIFICATION_TO_APP_URL, { data: Object.values(updates)[0], path: Object.keys(updates)[0].replace(ROOT_EDITOR_CONTENT_KEY, ""), action: NOTIFICATION_UPDATED });//this will help to send notification to app

      }
      error = true;
    } else {
      error = false;
    }

  }
  catch (error) {

    error = error.message;
    captureException(error, "error");

  }
  return error;
}
export const delete_section = async (key) => {

  let error = false;

  try {

    let deleteDataRes = await axios.post(DELETE_DATA_FROM_FIREBASE_DB, { pathToDeleteData: key }, {

      headers: {
        "Authorization": `Bearer ${localStorage.getItem("JWTToken").trim()}`,
      }
    });
    if (!deleteDataRes.data.error) {
      if (key.search(ROOT_EDITOR_CONTENT_KEY) >= 0) {
        let notificationPath = key.replace(ROOT_EDITOR_CONTENT_KEY, "")
        if (notificationPath != "/") {
          axios.post(SEND_NOTIFICATION_TO_APP_URL, { data: null, path: notificationPath, action: NOTIFICATION_DELETED });
        }
      }
      error = true;
    } else {
      error = false;
    }

  } catch (error) {
    error = false;
    captureException(error, "error");

  }
  return error;

}

export const setOrderPageOpration = async (data) => {
  const updates = {};
  let error = false;
  let updatePathForNotification = [];
  let updateOrderInPageIndexData = [];

  try {
    data.forEach((linkDestination, index) => {

      updatePathForNotification.push(linkDestination.replace(ROOT_EDITOR_CONTENT_KEY, "") + 'orderInPage');

      updateOrderInPageIndexData.push(index.toString());

      updates[linkDestination + 'orderInPage'] = index;

    });
    let updateRes = await axios.post(UPDATE_DATA_IN_FIREBASE_DB, { AllData: updates }, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("JWTToken").trim()}`,
      }
    });

    if (!updateRes.data.error) {
      axios.post(SEND_NOTIFICATION_TO_APP_URL, { data: updateOrderInPageIndexData, path: updatePathForNotification, action: NOTIFICATION_ORDERINPAGE });
      error = true;
    } else {
      error = false;
    }

  }

  catch (error) {
    error = error.message;
    captureException(error, "error");
  }
  return error;
}







