import { useEffect, createContext, useContext } from 'react';
import { getAuth, signInWithCustomToken, signOut as firebaseSignOut } from 'firebase/auth';
import { SIGN_IN_WITH_EMAIL_AND_PASSWORD, GET_USER_DATA_FROM_FIREBASE_AUTH } from '../constant/constant';
import { useAuthState } from 'react-firebase-hooks/auth';
import axios from "axios";

export const signIn = async ({ email, pass }) => {
    const auth = getAuth();
    const res = await axios.post(SIGN_IN_WITH_EMAIL_AND_PASSWORD, { email: email, password: pass })
    if (res.data.error) {
        return res.data
    }
    const token = res.data.token;
    await signInWithCustomToken(auth, token);
    return res.data
}

export const getUserDataFromFirebaseAuth = async ({ userId }) => {
    const res = await axios.post(GET_USER_DATA_FROM_FIREBASE_AUTH, { userUid: userId }, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("JWTToken").trim()}`,
            "Content-Type": "application/json",
            

        },
    });
    return res.data;
}

export const signOut = async () => {
    const auth = getAuth();
    await firebaseSignOut(auth);
  };