// import "./styles.css";
import * as React from "react";
import { useState } from "react";
import { Reorder } from "framer-motion";
import { ListPage, ContentPage, EditContentElementPage, ROOT_EDITOR_CONTENT_KEY, GROUP_PAGE } from "../../constant/constant";
import '../../style/sortableOrder/sortableOrder.css';


export default function OrderListComponent({ reduxData, setReorderData, type }) {

    const [allReduxData, setAllReduxData] = useState(reduxData ? Object.values(reduxData).toSorted((a, b) => a.orderInPage - b.orderInPage) : []);
    const changevent = (event, info) => {
        if (allReduxData.length > 0) {
            var data = [];
            allReduxData.forEach(element => {
                if (reduxData.linkDestination != undefined && element.key != undefined) {

                    data.push((type == "editor") ? ROOT_EDITOR_CONTENT_KEY + reduxData.linkDestination + element.key + "/" : reduxData.linkDestination + element.key + "/")

                } else if (element.linkDestination != undefined) {

                    data.push((type == "editor") ? ROOT_EDITOR_CONTENT_KEY + element.linkDestination : element.linkDestination)
                }
            })
            setReorderData(data)
            data = [];
        }
    }
    return (
        <>
            <div className="">
                <Reorder.Group values={allReduxData} onReorder={setAllReduxData}  >
                    {(allReduxData.length > 0) ?
                        allReduxData.map((key, index) => {
                            if (key.navPush === ListPage || key.navPush === ContentPage || key.navPush === EditContentElementPage || key.navPush === GROUP_PAGE) {
                                return (
                                    <Reorder.Item style={{ listStyleType: "none", width: "100%" }} onDragEnd={changevent} key={key.key} value={key}  >
                                        <>
                                            <div className="col-12 " data-check-all="checkAll">
                                                <div className="card d-flex  mb-3 ">
                                                    <div className=" flex-grow-1 min-width-zero">
                                                        <div className="card-body align-self-center flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                                                            <div className='row list-item-heading pb-3 align-items-center'>
                                                                <div className='col-md-11' id={key.key}>
                                                                    {key?.text || key?.title || key?.name}
                                                                </div>
                                                                <div className=" col-md-1  drag-handle float-end"  ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </Reorder.Item>
                                )
                            } else {
                                return (index == 0 && <div className=" d-flex justify-content-center align-items-center bg-white" style={{ "height": "10vh" }}>

                                    <h2>No Data Found</h2>

                                </div>)
                            }
                        }) :

                        <div className=" d-flex justify-content-center align-items-center bg-white" style={{ "height": "10vh" }}>

                            <h2>No Data Found</h2>

                        </div>
                    }

                </Reorder.Group>
            </div>

        </>
    );
}
