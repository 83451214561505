import { QBankCategory } from "./constant/constant";

export const recursionData = (obj = {}, key, type = "", editValue = "", propt = "") => {
    let result = {};
    const recursiveSearch = (obj = {}) => {

        if (!obj || typeof obj !== 'object') {
            return
        };

        Object.keys(obj).forEach(function (k) {

            if (k === key) {

                result = obj[key]

            };

            recursiveSearch(obj[k]);
        });
    }

    recursiveSearch(obj);

    if (type == "onlyKey") {
        return result;
    }
    return obj;


}


export const reduxSearchData = (obj = {}, value) => {

    const result = [];
    const recursiveSearch = (obj = {}) => {
        if (!obj || typeof obj !== 'object') {
            return;
        };
        if (obj.hasOwnProperty('navPush')) {
            if (obj['navPush'] == 'ListPage' || obj['navPush'] == 'ContentPage' || obj['navPush'] ==  QBankCategory) {
                if ((obj['text'] && obj['text'].length) && (obj['text'].toLowerCase().search(value.toLowerCase()) != -1)) {

                    result.push(obj);
                } else if (obj['navPush'] == 'ContentPage' && obj['keywords'] != undefined) {

                    const keyWordArray = obj['keywords'].split(' ');

                    if (keyWordArray.length) {

                        keyWordArray.forEach(element => {

                            if (element.toLowerCase().search(value.toLowerCase()) != -1) {
                                result.push(obj);

                            }

                        });
                    }

                }
            }
        }

        Object.keys(obj).forEach(function (k) {
            recursiveSearch(obj[k]);
        });
    }
    recursiveSearch(obj);
    return result;
}


