import React from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import { consoleFxn } from '../../console/console';
import { ADD_NEW_AT_ROOT, ADD_NEW_CONTACT, EDIT_TEXT_AND_SHORT_TEXT } from '../../constant/constant';
import { useDispatch } from 'react-redux';



const AddNewPhoneDirectoryModel = ({ hospital, showAddNewContactModel, editedData, lastIndexofArray, editvalueIndex, userKey, type, groupData, loading, setLoading }) => {

    const contact_type = [
        {
            name: "Call",
            value: "Call"
        },
        {
            name: "Text",
            value: "Text"
        },
        {
            name: "Both",
            value: "Both"
        },
    ];
    const dispatch = useDispatch();
    // -----------------------------------handel new contact form submit ----------------------
    const [addNew, setAddNewInput] = useState({
        name: (editedData != undefined && editedData?.name) ? editedData?.name : "",
        hospital: (editedData != undefined && editedData?.hospital) ? editedData?.hospital : "",
        number: (editedData != undefined && editedData?.number) ? editedData?.number : "",
        notes: (editedData != undefined && editedData?.notes) ? editedData?.notes : "",
        contact_type: (editedData != undefined && editedData?.contact_type) ? editedData?.contact_type : "",
        group: (editedData != undefined && editedData?.group) ? editedData?.group : "",
    });

    let name, value;
    const getRecord = (event) => {
        name = event.target.name;
        value = event.target.value;
      
        setAddNewInput({ ...addNew, [name]: value });
    };
    const handleNewContactFormSubmit = (e) => {
        e.preventDefault();

        if (addNew.name.trim() == "" || addNew.number.trim() == "" || addNew.contact_type.trim() == "" || addNew.contact_type.trim() == "-1" || addNew.group.trim() == "" || addNew.group.trim() == "-1") {

            notify("All Field Must Be Filled", "error");
            return;

        } else if (addNew.hospital.trim() == "" || addNew.hospital == -1) {

            notify("Please Select Hospital !", "error");
            return;

        }
        else if (addNew.number.length >= 15) {

            notify("Please Enter Valid Number!", "error");
            return;
        }
        else {

            setLoading(true);
            if (editedData == undefined) {
               
                dispatch({ type: ADD_NEW_AT_ROOT, linkDestination: "/policies/documents/" + userKey + "/entries/" + ((lastIndexofArray > 0) ? lastIndexofArray : "0"), navPush: ADD_NEW_CONTACT, text: addNew.name, data: addNew, contentType: type });

            } else if (editedData != undefined && editvalueIndex != undefined) {

                dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: addNew, key: "/policies/documents/" + userKey + "/entries/" + editvalueIndex + "/", propt: ["name", "hospital", "number", "notes", "contact_type", "group"], contentType: type });

            }


        }
    };

    return (

        <>
            {/* <ToastDefault /> */}
            <div className="modal modal-right show d-block" id="exampleModalRight" tabIndex="-1" role="dialog" aria-labelledby="exampleModalRight" style={{ "paddingRight": "17px", "background": "rgba(0,0,0,0.7)" }} aria-modal="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">{(editedData != undefined) ? "Edit Contact" : "Add New Contact"}</h5>

                            <button type="button" className="btn-close" onClick={() => showAddNewContactModel()}></button>

                        </div>
                        <div className="modal-body">

                            <form onSubmit={(e) => handleNewContactFormSubmit(e)} >
                                <Form.Group className="mt-3" >
                                    <Form.Label className='fw-bold'>Name</Form.Label>
                                    <Form.Control type="text" className='fw-bold' autoFocus placeholder="Enter Name" name='name' defaultValue={addNew.name} onChange={(e) => getRecord(e)} />
                                </Form.Group>
                                <Form.Group className="mt-3" >
                                    <Form.Label className='fw-bold'>Select Hospital</Form.Label>
                                    <Form.Select aria-label="Default select example" name='hospital' defaultValue={addNew.hospital} onChange={(e) => getRecord(e)}>
                                        <option value="-1">--Select Hospital--</option>
                                        {hospital && hospital.map((item, index) => {
                                            return (

                                                <option key={index} value={item} >{item}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mt-3" >
                                    <Form.Label className='fw-bold'>Phone Number</Form.Label>
                                    <Form.Control type="text" className='fw-bold' placeholder="Enter Phone Number" name='number' defaultValue={addNew.number} onChange={(e) => getRecord(e)} />
                                </Form.Group>
                                <Form.Group className="mt-3" >
                                    <Form.Label className='fw-bold'>Select Contact Type</Form.Label>
                                    <Form.Select aria-label="Default select example" name='contact_type' defaultValue={addNew?.contact_type}
                                        onChange={(e) => getRecord(e)}>
                                        <option value="">--Select Contact Type--</option>
                                        {contact_type && contact_type.map((item, index) => {
                                            return (

                                                <option key={index} value={item?.value}  >{item?.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mt-3" >
                                    <Form.Label className='fw-bold'>Select Group</Form.Label>
                                    <Form.Select aria-label="Default select example" name='group' defaultValue={addNew?.group}
                                        onChange={(e) => getRecord(e)}>
                                        <option value="-1">--Select Group--</option>
                                        {(groupData != undefined && Object.keys(groupData).length > 0) && Object.values(groupData).map((item, index) => {

                                            return (

                                                <option key={index} value={item?.key}  >{item?.name}</option>
                                            )
                                        })}
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label className='fw-bold'>Notes</Form.Label>
                                    <Form.Control as="textarea" rows={10} className='fw-bold' defaultValue={addNew.notes} name='notes' onChange={(e) => getRecord(e)} style={{ height: 'auto' }} />
                                </Form.Group>
                                <div className='text-end'>
                                    <Button variant="primary" className='mt-3' type="submit" disabled={loading}>
                                        {/* {loading.isLoading ? 'Submiting…' : 'Submit'} */}
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default AddNewPhoneDirectoryModel
