export const ContentPage = 'ContentPage';
export const ListPage = 'ListPage';
export const QBankCategory = 'Q_Bank_Category';
export const QBankQuestion = 'Q_Bank_Question';
export const EditContentElementPage = 'EditContentElementPage';
export const ADD_NEW_HOSPITAL = 'ADD_NEW_HOSPITAL';
export const GROUP_PAGE = 'GROUP_PAGE';
export const ADD_NEW_CONTACT = 'ADD_NEW_CONTACT';
export const ADD_NEW_INSTITUTE = 'ADD_NEW_INSTITUTE';

// crud operation realetd constent will be here
export const EDIT_TEXT_AND_SHORT_TEXT = 'EDIT_TEXT_AND_SHORT_TEXT';
export const DELETE_SECTION = 'DELETE_SECTION';
export const ADD_NEW_AT_ROOT = 'ADD_NEW_AT_ROOT';
export const SET_ORDER_PAGE = 'SET_ORDER_PAGE';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const EDIT_QBANK_QUESTION = 'EDIT_QBANK_QUESTION'

export const FORGOT_PASSWORD_OTP = 'FORGOT_PASSWORD_OTP'



// add new user related constant will be here
export const FULL_CONTENT_ACCESS = 'Full Content';
export const POLICIES_PAGE_ACCESS = 'Policies';
export const ROOT_EDITOR_CONTENT_KEY = "/editor_content"

// ---------------node route will be here----------------------//

export const BASE_URL = "https://us-central1-indigo-live-a5a47.cloudfunctions.net"

export const CREATE_NEW_USER_IN_FIREBASE_AUTHENTICATION = BASE_URL + '/createNewUser';

export const DELETE_USER_IN_FIREBASE_AUTHENTICATION = BASE_URL + '/deleteUserFromAuth';

export const UPDATE_USER_INFO_IN_FIREBASE_AUTHENTICATION = BASE_URL + '/updateUserRecordFromAuth';

export const SET_DATA_TO_FIREBASE_DB = BASE_URL + '/setData';

export const DELETE_DATA_FROM_FIREBASE_DB = BASE_URL + '/deleteData';

export const UPDATE_DATA_IN_FIREBASE_DB = BASE_URL + '/updateData';

export const SIGN_IN_WITH_EMAIL_AND_PASSWORD = BASE_URL + '/signInWithEmailAndPassword';

export const GET_DATA_FROM_FIREBASE_DB = BASE_URL + '/getDataFromDatabase';

export const GET_USER_DATA_FROM_FIREBASE_AUTH = BASE_URL + '/getUserDataFromAuth';

export const SEND_NOTIFICATION_TO_APP_URL = BASE_URL + '/getDataFromAdmin';

export const GET_GPT_RESPONSE = BASE_URL + "/getGptResponse";

export const SEND_OTP_FOR_FORGOT_PASSWORD = BASE_URL + "/sendForgotPasswordOtpMail";



export const NOTIFICATION_ADDED = "Added";
export const NOTIFICATION_DELETED = "Deleted";
export const NOTIFICATION_UPDATED = "Updated";
export const NOTIFICATION_ORDERINPAGE = "OrderInPage";

export const FIREBASE_STORAGE_PATH = "/test_images/";







