
import profileImg from '../../assets/images/profile.jpeg';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { auth } from '../../firebaseDb/config/firebase';
// import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { emptyReduxData } from '../../redux/features/fetchAllRecord';
import { useDispatch } from 'react-redux';
import { signOut } from '../../firebaseDb/auth';
import { captureException } from '@sentry/react';



const TopNav = ({ setAuthenticationStatus, userPermission, setUserPermission }) => {
    const [navToggle, setNavToggle] = useState(false);

    const dispatch = useDispatch()
    useEffect(() => {
        if (document.body.classList.length == 0) {
            document.body.classList.add("sub-hidden", "menu-default", "main-hidden");
        } else {
            document.body.classList.remove("sub-hidden", "menu-default", "main-hidden");
        }
    }, [navToggle]);

    // toggle nav bar event 
    // handle logout status here 
    const navigate = useNavigate();

    const handleLogout = async () => {

        signOut().then(() => {
            // Sign-out successful.
            localStorage.clear();
            setAuthenticationStatus(false);
            dispatch(emptyReduxData());
            setUserPermission({});
            // navigate("/");
        }).catch((error) => {
            // An error happened.
            captureException(error,"error");

        });
    }

    return <>
        <nav className="navbar fixed-top d-flex align-items-center" style={{ "opacity": "1" }}>
            <div className="d-flex align-items-center navbar-left">
                <a className="menu-button d-none d-md-block" onClick={() => setNavToggle(navToggle ? false : true)}>
                    <svg className="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
                        <rect x="0.48" y="0.5" width="7" height="1"></rect>
                        <rect x="0.48" y="7.5" width="7" height="1"></rect>
                        <rect x="0.48" y="15.5" width="7" height="1"></rect>
                    </svg>
                    <svg className="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
                        <rect x="1.56" y="0.5" width="16" height="1"></rect>
                        <rect x="1.56" y="7.5" width="16" height="1"></rect>
                        <rect x="1.56" y="15.5" width="16" height="1"></rect>
                    </svg>
                </a>
                <a className="menu-button-mobile d-xs-block d-sm-block d-md-none">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                        <rect x="0.5" y="0.5" width="25" height="1"></rect>
                        <rect x="0.5" y="7.5" width="25" height="1"></rect>
                        <rect x="0.5" y="15.5" width="25" height="1"></rect>
                    </svg>
                </a>
                {/* <div className="search" data-search-path="Pages.Search.html?q=">
                    <input placeholder="Search..." />
                    <span className="search-icon">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </span>
                </div> */}
            </div>
            <Link to={"/"}>
                <div className='logo-mobile' style={{ "height": "3rem" }}></div>
            </Link>
            <div className="navbar-right ">
                {/* <div className="header-icons d-inline-block align-middle">
                    <div className="d-none d-md-inline-block align-text-bottom mr-3">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        </div>
                    </div>
                    <div className="position-relative d-none d-sm-inline-block">
                        <button className="header-icon btn btn-empty dropdown" type="button" id="iconMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={faGrip} size="2xl" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right mt-3  position-absolute" id="iconMenuDropdown">
                            <a href="#" className="icon-menu-item">
                                <i className="iconsminds-equalizer d-block"></i>
                                <span>Settings</span>
                            </a>

                            <a href="#" className="icon-menu-item">
                                <i className="iconsminds-male-female d-block"></i>
                                <span>Users</span>
                            </a>

                            <a href="#" className="icon-menu-item">
                                <i className="iconsminds-puzzle d-block"></i>
                                <span>Components</span>
                            </a>

                            <a href="#" className="icon-menu-item">
                                <i className="iconsminds-bar-chart-4 d-block"></i>
                                <span>Profits</span>
                            </a>

                            <a href="#" className="icon-menu-item">
                                <i className="iconsminds-file d-block"></i>
                                <span>Surveys</span>
                            </a>

                            <a href="#" className="icon-menu-item">
                                <i className="iconsminds-suitcase d-block"></i>
                                <span>Tasks</span>
                            </a>

                        </div>
                    </div>

                    <div className="position-relative d-inline-block">
                        <button className="header-icon btn btn-empty" type="button" id="notificationButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={faBell} size='xl' />
                            <span className="count">3</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right mt-3 position-absolute" id="notificationDropdown">
                            <div className="scroll ps">
                                <div className="d-flex flex-row mb-3 pb-3 border-bottom">
                                    <a href="#">
                                        <img src="img/profiles/l-2.jpg" alt="Notification Image" className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                                    </a>
                                    <div className="pl-3">
                                        <a href="#">
                                            <p className="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                                            <p className="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex flex-row mb-3 pb-3 border-bottom">
                                    <a href="#">
                                        <img src="img/notifications/1.jpg" alt="Notification Image" className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                                    </a>
                                    <div className="pl-3">
                                        <a href="#">
                                            <p className="font-weight-medium mb-1">1 item is out of stock!</p>
                                            <p className="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex flex-row mb-3 pb-3 border-bottom">
                                    <a href="#">
                                        <img src="img/notifications/2.jpg" alt="Notification Image" className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                                    </a>
                                    <div className="pl-3">
                                        <a href="#">
                                            <p className="font-weight-medium mb-1">New order received! It is total $147,20.</p>
                                            <p className="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex flex-row mb-3 pb-3 ">
                                    <a href="#">
                                        <img src="img/notifications/3.jpg" alt="Notification Image" className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                                    </a>
                                    <div className="pl-3">
                                        <a href="#">
                                            <p className="font-weight-medium mb-1">3 items just added to wish list by a user!
                                            </p>
                                            <p className="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="ps__rail-x" style={{ "left": "0px", "bottom": "0px" }}><div className="ps__thumb-x" tabIndex="0" style={{ "left": "0px", "width": "0px" }}></div></div><div className="ps__rail-y" style={{ "top": "0px", "right": "0px" }}><div className="ps__thumb-y" tabIndex="0" style={{ "top": "0px", "height": "0px" }}></div></div></div>
                        </div>
                    </div>

                    <button className="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton">
                        <FontAwesomeIcon icon={faMaximize} size='xl' />
                        <i className="simple-icon-size-actual"></i>
                    </button>

                </div> */}

                <div className="user d-inline-block">
                    <button className="btn btn-danger" onClick={() => handleLogout()} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {/* <span>
                            <img alt="Profile Picture" src={profileImg} />
                        </span> */}
                        Logout
                    </button>
                    <div className="dropdown-menu dropdown-menu-right mt-3">
                        <a className="dropdown-item" href="#">Account</a>
                        <a className="dropdown-item" href="#">Features</a>
                        <a className="dropdown-item" href="#">History</a>
                        <a className="dropdown-item" href="#">Support</a>
                        <a className="dropdown-item" href="#">Sign out</a>
                    </div>
                </div>
                {/* <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" >
                        <span>
                            <img alt="Profile Picture " src={profileImg} />
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
        </nav>
    </>

}

export default TopNav;