import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { faPlus, faPenToSquare, faTrash, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import Button from 'react-bootstrap/Button';
import { ADD_NEW_HOSPITAL, ADD_NEW_AT_ROOT, DELETE_SECTION, EDIT_TEXT_AND_SHORT_TEXT } from '../../constant/constant';
import Swal from 'sweetalert2';

const HospitalModel = ({ setHospitalModelVisablityFunction, data, linkDestination }) => {

    // handle new hospital form submit 
    const dispatch = useDispatch()

    // on add new form submit 
    const [addNew, setAddNewInput] = useState({
        name: "",
    });

    let name, value;
    const getRecord = (event) => {

        name = event.target.name;
        value = event.target.value;
        setAddNewInput({ ...addNew, [name]: value });
    }
    const handleNewHospitalFormSubmit = (e) => {
        e.preventDefault();
        if (addNew.name.trim() != "") {
            let setDat = "";
            if (data && data.length > 0) {
                setDat = [...data, addNew.name]
            }
            else {
                setDat = [addNew.name]
            }
            dispatch({ type: ADD_NEW_AT_ROOT, linkDestination: linkDestination, data: setDat, navPush: ADD_NEW_HOSPITAL, contentType: "hospital" });
            e.target.reset()

        } else {

            notify("Please Add New Hospital Name !", "error");
        }


    }

    // -----------------handle delete event----------------//
    const handleDeleteEvent = (deletedValue, index) => {
        Swal.fire({
            title: "Are you sure you want to delete \"" + deletedValue + "\" ?",
            // text: "All chiled node of \"" + deletedValue + "\" will also be deleted",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: DELETE_SECTION, key: linkDestination + "/" + index, value: deletedValue })

            }
        });

    }
    // handle toggale edit event 
    const [editValue, setEditValue] = useState({});
    const handleToggleEditEvent = (index, editValue = "") => {

        setAddNewInput({ name: editValue })
        setEditValue({ index: index })

    }

    function edit({ propt, index }) {

        if (addNew.name.trim() != "") {
            // console.log(index)
            dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { [index]: addNew.name }, key: linkDestination, propt });

            setAddNewInput({
                name: "",
            });
            setEditValue({});

        } else {

            notify("Please Enter Hospital Name !", "error");
        }

    }
    /////////// close editing section ///////////////

    return (
        <>
            {/* <ToastDefault /> */}
            <div className="modal modal-right show d-block " id="hospitalModel" tabIndex="-1" role="dialog" aria-labelledby="hospitalModel" style={{ "paddingRight": "17px", "background": "rgba(0,0,0,0.6)" }} aria-modal="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold ">Hospital</h5>
                            <button type="button" className="btn-close" onClick={() => setHospitalModelVisablityFunction()}></button>
                        </div>
                        <div className="modal-body">

                            <form onSubmit={(e) => { handleNewHospitalFormSubmit(e) }}>
                                <Form.Group className="mt-3" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Enter Name" onChange={(e) => getRecord(e)} name='name' />
                                </Form.Group>
                                <div className="form-group mt-3">
                                    <Button variant="primary" type="submit">
                                        {/* {loading.isLoading ? 'Submiting…' : 'Submit'} */}
                                        Submit
                                    </Button>
                                </div>
                            </form>

                            <div>
                                <div className="mt-2">
                                    <div className="">
                                        <div className="">
                                            <h2 class="card-title mt-5 mb-2  text-center">List of Hospital</h2>
                                            <hr />
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {(data && data.length > 0) ? data.map((key, index) => {

                                                        return (
                                                            <>
                                                                <tr key={index} >

                                                                    <td style={{ wordBreak: "break-word" }}>
                                                                        {(editValue.index == index) ?
                                                                            <>
                                                                                <input type="text" style={{ width: "10rem" }} 
                                                                                className='fw-bold' name='name' onChange={(e) => getRecord(e)} value={addNew.name} />

                                                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} 
                                                                                onClick={() => setEditValue({})} className='ms-3 text-primary' icon={faXmark} />

                                                                                <FontAwesomeIcon style={{ cursor: 'pointer' }}
                                                                                 className='ms-3 text-primary' onClick={() => edit({ propt: [index], index: index })} icon={faCheck} />
                                                                            </>
                                                                            :
                                                                            <b>  {key}</b>
                                                                        }
                                                                    </td>
                                                                    <td>

                                                                        <FontAwesomeIcon className='text-success mx-3' size='xl'
                                                                         onClick={() => handleToggleEditEvent(index, key)} icon={faPenToSquare} />

                                                                        <FontAwesomeIcon className='text-danger' size='xl'
                                                                         onClick={() => handleDeleteEvent(key, index)} icon={faTrash} />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={2} className='text-center fw-bold text-danger fs-5'>
                                                                No Record Found
                                                            </td>
                                                        </tr>

                                                    }
                                                </tbody >
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HospitalModel
