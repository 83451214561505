import React, { useEffect, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { notify } from '../toasts/ToastDefault';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_QBANK_QUESTION } from '../../constant/constant';


import DeepLinkSection from '../main/DeepLinkSection';

function Question({ data, index, handleDeleteEvent, isLoading, setLoading, setIsEditorVisible }) {
    const dispatch = useDispatch()
    //----------------------------------------//
    const modeOfDifficulty = [
        { name: 'Easy', value: '1' },
        { name: 'Medium', value: '2' },
        { name: 'Hard', value: '3' },
    ];

    const [questionChoices, setQuestionChoices] = useState(() => {
        let newdata = data?.questionChoices.map((element, index) => {
            return {
                choice: element.choice,
                isCorrect: element.isCorrect
            }
        })
        return newdata
    });

    useEffect(() => {
        if (data?.questionChoices) {
            setQuestionChoices(() => {
                // console.log("i am use effect", data);
                let newdata = data?.questionChoices.map((element, index) => {
                    return {
                        choice: element.choice,
                        isCorrect: element.isCorrect
                    }
                })
                return newdata
            })
        }

    }, [data]);

    const handleAddChoices = (index) => {

        if (questionChoices.length > 26) {
            return -1;
        }
        setQuestionChoices([...questionChoices, {
            choice: "",
            isCorrect: false
        }]);
    };

    const handleDeleteChoice = (index) => {
        if (questionChoices.length == 1) {
            return -1
        }
        const newQuestionChoices = [...questionChoices];
        // console.log(newQuestionChoices, index);
        newQuestionChoices.splice(index, 1);
        // console.log(newQuestionChoices, index)
        setQuestionChoices(newQuestionChoices);
    };

    const getChoiceRecord = (name, value, index) => {

        let onChangeValue = [...questionChoices];
        // console.log(onChangeValue, questionChoices);
        if (name == "isCorrect") {
            onChangeValue.forEach((element, i) => {
                element.isCorrect = false;
            });
        }

        onChangeValue[index][name] = value;
        // console.log(onChangeValue);/
        setQuestionChoices(onChangeValue);
    }

    // handle edit section here ------------------------//
    const [values, setvalues] = useState(
        {
            subject: data?.subject,
            keywords: data?.keywords,
            Qbody: data?.Qbody,
            QExplanation: data?.QExplanation,
            QReference: data?.QReference,
            modeOfDifficulty: data?.modeOfDifficulty,
            publishStatus: data?.publishStatus
        }
    );
    const onChangeValue = (name, value) => {
        setvalues({ ...values, [name]: value });
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        let index = event.target.getAttribute("data-index");
        let modeOfDifficulty = (values["modeOfDifficulty_" + index] != undefined) ? values["modeOfDifficulty_" + index] : values.modeOfDifficulty;
        let publishStatus = (values["publishStatus_" + index] != undefined) ? values["publishStatus_" + index] : values.publishStatus;

        if ((publishStatus) && (values.subject.trim() == "" || values.keywords.trim() == "" || values.Qbody.trim() == "" || values.QExplanation.trim() == "" ||
            values.QReference.trim() == "" || questionChoices.length == 0 || modeOfDifficulty.trim() == "")) {

            notify("All Field Must Be Filled", "error");
            return;

        } else if ((publishStatus) &&
            ((questionChoices?.filter(choice => choice?.choice?.trim() == "")?.length > 0) || (questionChoices?.filter(choice => choice.isCorrect == true).length == 0))) {

            notify("Select Correct Choices !", "error");
            return;

        }
        else {
            setLoading(true);
            let setData = {
                subject: values.subject,
                keywords: values.keywords,
                Qbody: values.Qbody,
                QExplanation: values.QExplanation,
                QReference: values.QReference,
                modeOfDifficulty: modeOfDifficulty,
                publishStatus: publishStatus,
                questionChoices: questionChoices
            }
            //    delete choice from question choice 
            // setQuestionChoices(questionChoices);
            dispatch({ type: EDIT_QBANK_QUESTION, key: data?.linkDestination, value: setData, propt: ["subject", "keywords", "Qbody", "QExplanation", "QReference", "modeOfDifficulty", "publishStatus", "questionChoices"] });

            // event.target.reset();

        }
    }

    useEffect(() => {
        if (data) {
            setvalues({
                subject: data?.subject,
                keywords: data?.keywords,
                Qbody: data?.Qbody,
                QExplanation: data?.QExplanation,
                QReference: data?.QReference,
                modeOfDifficulty: data?.modeOfDifficulty,
                publishStatus: data?.publishStatus
            })
        }
    }, [data]);

    return (
        <>
            <div className="card d-flex flex-row mb-3 " id={data?.key} data={data?.key}>
                <div className="flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                        <form onSubmit={(e) => { handleFormSubmit(e); }} data-index={index}>
                            <div className="row bg-white">
                                <div className="col-12  mb-3 " id={'headingToHihlight_' + data.key}>
                                    <span className='h2'>  Question {index + 1}.</span>
                                    <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteEvent(data?.linkDestination, "Question" + (index + 1))} className='ms-3 text-primary' size='lg' icon={faTrash} />
                                </div>
                                <div className="col-12 mb-3" >
                                    <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb" style={{ border: "0.1rem solid #dee2e6" }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className='h6'>Subject</div>
                                                <div className="form-group">
                                                    <input className="form-control"
                                                        placeholder='Enter Subject Here'
                                                        name='subject'
                                                        type="text"
                                                        defaultValue={data?.subject}
                                                        onChange={(e) => onChangeValue(e.target.name, e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='h6'>Keywords <small>(Seperate keywords by spaces.)</small></div>
                                                <div className="form-group">
                                                    <textarea className="form-control"
                                                        defaultValue={data?.keywords} rows="3"
                                                        placeholder='Enter Keywords'
                                                        name='keywords'
                                                        onChange={(e) => onChangeValue(e.target.name, e.target.value)}>
                                                    </textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="body"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="body" title="Question Body">

                                    <div className='text-end cursor-pointer'
                                        onClick={() => setIsEditorVisible({
                                            body: data?.Qbody,
                                            path: data?.linkDestination,
                                            changeKey: "Qbody"
                                        })}>
                                        <FontAwesomeIcon icon={faPencil} size='lg' />

                                    </div>


                                    <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb"
                                    // ref={changeEditorbg}
                                    >
                                        <p dangerouslySetInnerHTML={{ __html: data?.Qbody }}>
                                        </p>
                                    </nav>
                                </Tab>
                                <Tab eventKey="choice" title="Question Choices">
                                    <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb">
                                        <div className='row'>
                                            {
                                                Object.values(questionChoices).map((item, index) => (
                                                    <div className='col-md-12 mb-3'>
                                                        <div className="row align-items-center ">
                                                            <div className="col-md-1 text-end">
                                                                <input
                                                                    name="isCorrect"
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    value={index}
                                                                    defaultChecked={item.isCorrect}
                                                                    onChange={(e) => getChoiceRecord(e.target.name, e.target.checked, index)}
                                                                />
                                                            </div>
                                                            <div className="col-md-10 d-flex align-items-center ">
                                                                <span className='mx-2 '>{String.fromCharCode(65 + index)})</span>
                                                                <textarea rows={3} value={item.choice} className="form-control" name="choice"
                                                                    onChange={(e) => getChoiceRecord(e.target.name, e.target.value, index)}  >

                                                                </textarea>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <button className='border-0 bg-light' type='button' onClick={() => handleDeleteChoice(index)}><FontAwesomeIcon size='lg' className=' text-danger' style={{ cursor: 'pointer' }} icon={faCircleXmark} /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className='text-end col-2'>
                                                <Button variant="primary" disabled={(questionChoices.length == 26)} className='mt-3' type="button" onClick={(e) => handleAddChoices()}>
                                                    Add Choice
                                                </Button>
                                            </div>
                                        </div>
                                    </nav>
                                </Tab>
                                <Tab eventKey="explanation" title="Question Explanation">

                                    <div className='text-end cursor-pointer'
                                        onClick={() => setIsEditorVisible({
                                            body: data?.QExplanation,
                                            path: data?.linkDestination,
                                            changeKey: "QExplanation",
                                        })}>
                                        <FontAwesomeIcon icon={faPencil} size='lg' />

                                    </div>
                                    <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb"
                                    >
                                        <p dangerouslySetInnerHTML={{ __html: data?.QExplanation }}>
                                        </p>
                                    </nav>
                                </Tab>
                                <Tab eventKey="References" title=" Question References">
                                    <div className='text-end cursor-pointer'
                                        onClick={() => setIsEditorVisible({
                                            body: data?.QReference,
                                            path: data?.linkDestination,
                                            changeKey: "QReference",
                                        })}>
                                        <FontAwesomeIcon icon={faPencil} size='lg' />

                                    </div>
                                    <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb"
                                    >
                                        <p dangerouslySetInnerHTML={{ __html: data?.QReference }}>
                                        </p>
                                    </nav>
                                </Tab>
                            </Tabs>
                            <div >
                                <div className="row align-items-center mx-3 my-3">
                                    <div className="col-8">
                                        <ButtonGroup>
                                            {modeOfDifficulty.map((radio, idx) => (
                                                <ToggleButton
                                                    data={idx + "_" + index}
                                                    id={`radio-${index}-btn-${idx}`}
                                                    type="radio"
                                                    variant={(idx == 0) ? 'outline-warning' : (idx == 1) ? 'outline-success' : 'outline-danger'}
                                                    name={"modeOfDifficulty_" + index}
                                                    value={radio.name}
                                                    checked={(values["modeOfDifficulty_" + index] != ("" || undefined)) ? (values["modeOfDifficulty_" + index] == radio.name) :
                                                        (data?.modeOfDifficulty == radio.name)}
                                                    onChange={(e) => (onChangeValue(e.target.name, e.target.value))}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                    <div className='text-end col-4 '>
                                        <ToggleButton
                                            className="mx-3"
                                            id={"toggle-check_" + index}
                                            type="checkbox"
                                            variant="outline-dark"
                                            checked={(values["publishStatus_" + index] != ("" || undefined)) ? (values["publishStatus_" + index]) : (data?.publishStatus)}
                                            name={'publishStatus_' + index}
                                            onChange={(e) => onChangeValue(e.target.name, e.currentTarget.checked)}
                                        >
                                            Publish
                                        </ToggleButton>
                                        <Button variant="primary" type="submit" disabled={isLoading} >
                                            Save Changes
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Question