import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./style/custom.scss"; //custome css of scss
import './style/nav/topNav.min.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import {configureSentry} from './components/common/sentry';


configureSentry(); // Initialize Sentry

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <App />
    {/* </PersistGate> */}
  </Provider>
);


