import React, { useState, useRef, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Editor } from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { notify } from '../toasts/ToastDefault';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_NEW_AT_ROOT, GET_GPT_RESPONSE, QBankQuestion } from '../../constant/constant';
import { storage } from '../../firebaseDb/config/firebase';
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import uuid from 'react-uuid';
import DeepLinkSection from './DeepLinkSection';
import axios from 'axios';
import { captureException } from '@sentry/react';
import Spinner from 'react-bootstrap/Spinner';
import { clearStep } from '../../redux/features/fetchAllRecord'

function AddNewQBankSectionTopModel({ toggleModelVisibility, linkDestination }) {

    const dispatch = useDispatch()
    const [DeepLinkVisiblity, setDeepLinkVisiblityFn] = useState(false);
    const [activeEditorIndex, setActiveEditorIndex] = useState(0);
    const [AIElementExtractorSectionVisiblity, setAIElementExtractorSectionVisiblityFn] = useState(false);
    const GenrateWithAiTextEditorRef = useRef(null);
    const QuestionChoiceAiTextEditorRef = useRef(null);
    const [QuestionChoiceAiTextEditorVisiblity, setQuestionChoiceAiTextEditorVisiblityFn] = useState(false);
    const [tabActiveKey, setTabActiveKeyFn] = useState("body");
    const intervalIDRef = useRef(null);
    // choice realted all code here

    const [questionChoices, setQuestionChoices] = useState([
        {
            choice: "",
            isCorrect: false
        }
    ]);

    const [spiner, setSpiner] = useState(false);

    let DeepLinkData = useSelector(state => state.AllData);

    const { QBankQuestionChoices } = useSelector(state => state);

    useEffect(() => {
        if (QBankQuestionChoices && QBankQuestionChoices.length > 0) {
            setQuestionChoices(QBankQuestionChoices);
            setQuestionChoiceAiTextEditorVisiblityFn(false);
            dispatch(clearStep({ key: "QBankQuestionChoices", value: [] }));
            setSpiner(false);
            clearInterval(intervalIDRef.current);
            setLoadinDisplayDataState(LoadingDisplayData[0]);
            setTabActiveKeyFn("choice");
        }
    }, [QBankQuestionChoices])

    const LoadingDisplayData = [
        "Sending Request...",
        "Bifercating Q bank data...",
        "Populating Output...",
    ];

    const [loadinDisplayDataState, setLoadinDisplayDataState] = useState(LoadingDisplayData[0]);


    // all new add values here 
    const [values, setvalues] = useState(
        {
            subject: "",
            keywords: "",
            Qbody: "",
            QExplanation: "",
            QReference: "",
            modeOfDifficulty: "Easy",
            publishStatus: true
        }
    );

    const onChangeValue = (name, value) => {
        // let { name, value } = event.target;
        // console.log(name, value);
        setvalues({ ...values, [name]: value });
    }

    // mode of difficulty values
    const modeOfDifficulty = [
        { name: 'Easy', value: '1' },
        { name: 'Medium', value: '2' },
        { name: 'Hard', value: '3' },
    ];



    const handleAddChoices = (index) => {

        if (questionChoices.length > 26) {
            return -1;
        }
        setQuestionChoices([...questionChoices, {
            choice: "",
            isCorrect: false
        }]);
    };

    const handleDeleteChoice = (index) => {
        if (questionChoices.length == 1) {
            return -1
        }
        const newQuestionChoices = [...questionChoices];
        newQuestionChoices.splice(index, 1);
        setQuestionChoices(newQuestionChoices);
    };

    const getChoiceRecord = (name, value, index) => {

        let onChangeValue = [];
        questionChoices.forEach((element, i) => {
            onChangeValue.push({ ...element });
        })
        if (name == "isCorrect") {
            onChangeValue.forEach((element, i) => {
                element.isCorrect = false;
            });
        }
        onChangeValue[index][name] = value;
        setQuestionChoices(onChangeValue);
    }

    // --------------------end here -------------------

    // handel form submit metod here 
    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (values.publishStatus && (values.subject.trim() == "" || values.keywords.trim() == "" || values.Qbody.trim() == "" || values.QExplanation.trim() == ""
            || values.QReference.trim() == "" || questionChoices.length == 0 || values.modeOfDifficulty.trim() == "")) {
            notify("All Field Must Be Filled", "error");
            return;

        } else if (values.publishStatus && ((questionChoices.filter(choice => choice.isCorrect == true).length == 0) || (questionChoices.filter(choice => choice.choice.trim() != "")).length == 0)) {

            notify("Select Atleast One Correct Choice", "error");
            return;
        }
        else {
            dispatch({ type: ADD_NEW_AT_ROOT, values, questionChoices, linkDestination, navPush: QBankQuestion, contentType: QBankQuestion });
            // toggleModelVisibility();
            event.target.reset();
        }


    }

    const image_upload_handler_fun = (blobInfo, progress) => new Promise((resolve, reject) => {

        const file = (typeof blobInfo.blob === "function") ? blobInfo.blob() : blobInfo;

        const storageRef = ref(storage, '/test_images/' + uuid() + "_" + file.name);


        uploadBytes(storageRef, file).then((snapshot) => {

            getDownloadURL(snapshot.ref).then((downloadURL) => {

                resolve(downloadURL);

            }).catch((error) => {

                reject("Error getting download URL:", error);
                // failure(error);
            });
        }).catch((error) => {

            reject("Error uploading image:", error);
        });


        // resolve('/uploaded/image/path/image.png');

    });

    const toggleDeepLinksectionVisiblity = (index) => {

        setDeepLinkVisiblityFn(!DeepLinkVisiblity);
        setActiveEditorIndex(index)
    }

    // handle to click event by genrate question from AI
    async function handleClickByGenrateWithAI() {
        setSpiner(true);
        let count = 0;
        const interval = setInterval(() => {
            if (count <= 2) {

                setLoadinDisplayDataState(LoadingDisplayData[count++]);
            }
        }, 3000);
        let textEditorValue = GenrateWithAiTextEditorRef.current.value;
        try {
            if (textEditorValue) {
                const AiResponse = await axios.post(GET_GPT_RESPONSE, { threadId: "", prompt: textEditorValue, output_mode: "questionWithChoices" });
                if (!AiResponse?.data?.error && AiResponse?.data?.responseData?.length > 0) {
                    let genratedData = "";
                    for (const message of AiResponse.data.responseData.reverse()) {
                        if (message?.role == "assistant") {

                            genratedData = JSON.parse(message?.content[0]?.text?.value);

                        }
                    }
                    if (genratedData) {
                        setvalues(
                            {
                                ...values,
                                Qbody: genratedData?.body || "",
                                QExplanation: genratedData?.explanation || "",
                                QReference: genratedData?.references || "",
                            }
                        );
                        setQuestionChoices((genratedData?.choices && genratedData?.choices.length > 0) ? genratedData?.choices : [
                            {
                                choice: "",
                                isCorrect: false
                            }
                        ]);
                        setAIElementExtractorSectionVisiblityFn(false);
                         setTabActiveKeyFn("body");

                    }
                }



            } else {
                notify("Please provide a valid input !", "error");

            }
            setSpiner(false);
            clearInterval(interval);
            setLoadinDisplayDataState(LoadingDisplayData[0]);

        } catch (error) {
            setLoadinDisplayDataState(LoadingDisplayData[0]);
            clearInterval(interval);
            notify("There is error to genrate question !", "error");
            setSpiner(false);
            captureException(error, "error");
        }

    }

    function handleGenrateChoicesWithAI() {

        let textEditorValue = QuestionChoiceAiTextEditorRef.current.value;
        if (textEditorValue && textEditorValue.trim() != "") {
            setSpiner(true);
            let count = 0;
            intervalIDRef.current = setInterval(() => {
                if (count <= 2) {
                    setLoadinDisplayDataState(LoadingDisplayData[count++]);
                }
            }, 3000);
            dispatch({ type: "GENRATE_WITH_AI", prompt: textEditorValue, output_mode: "only_choices" });


        } else {
            notify("Please provide a valid input !", "error");
        }
    }

    return (
        <>
            <DeepLinkSection show={DeepLinkVisiblity} onHide={() => setDeepLinkVisiblityFn(false)} data={DeepLinkData.editorContent} toggleDeepLinksectionVisiblity={toggleDeepLinksectionVisiblity} activeEditorIndex={activeEditorIndex} />

            <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" style={{ "display": "block", "paddingRight": "15px" }} aria-modal="true">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content bg-light shadow rounded-4" style={{ "border": "1px solid rgba(102,88,155,0.3)" }}>
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">Add New Question</h5>
                            <button type="button" onClick={() => { toggleModelVisibility() }} className="btn-close"></button>
                        </div>
                        <div className="modal-body h-100">
                            <form onSubmit={(e) => handleFormSubmit(e)}>
                                <div className="card-body align-self-center flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                                    <div className="row mb-4">
                                        <div className="col-12 mb-3" >
                                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb" style={{ border: "0.1rem solid #dee2e6" }}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className='h6'>Subject</div>
                                                        <div className="form-group">
                                                            <input className="form-control" placeholder='Enter Subject Here' type="text" value={values.subject} name='subject'
                                                                onChange={(e) => onChangeValue(e.target.name, e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='h6'>Keywords <small>(Seperate keywords by spaces.)</small></div>
                                                        <div className="form-group">
                                                            <textarea className="form-control" rows="3" placeholder='Enter Keywords' name='keywords' value={values.keywords}
                                                                onChange={(e) => onChangeValue(e.target.name, e.target.value)}>
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                            </nav>
                                        </div>
                                        <div className="col-12 text-end">
                                            <Button className={`bg-${AIElementExtractorSectionVisiblity ? "info" : "primary"} border-0`}
                                                disabled={spiner}
                                                onClick={() => setAIElementExtractorSectionVisiblityFn(!AIElementExtractorSectionVisiblity)}>
                                                {!AIElementExtractorSectionVisiblity ? "Genrate With AI" : "Genrate Manually"}</Button>
                                        </div>
                                    </div>
                                    {
                                        (spiner) ?
                                            <div className='text-center'>
                                                <h3>
                                                    {loadinDisplayDataState}
                                                </h3>
                                                <Spinner animation="border" variant="danger" />
                                            </div>
                                            :
                                            (AIElementExtractorSectionVisiblity)
                                                ?
                                                <div>
                                                    <textarea ref={GenrateWithAiTextEditorRef} rows={8} style={{ "width": "99%" }} placeholder='Paste your content here. Please make that all topics of content are provided eg. Question Body, Question choices, Correct Answer, Question Explanation and Question References' />
                                                    <Button onClick={() => { handleClickByGenrateWithAI() }} className='float-end'>Genrate</Button>
                                                </div>
                                                :
                                                <div>
                                                    <Tabs
                                                        defaultActiveKey={tabActiveKey}
                                                        id="fill-tab-example"
                                                        className="mb-3"
                                                        fill >
                                                        <Tab eventKey="body" title="Question Body">
                                                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb"
                                                            // ref={changeEditorbg}
                                                            >
                                                                <Editor
                                                                    apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                                                                    onEditorChange={(newValue, editor) => {
                                                                        // if (editor.isDirty()) {
                                                                        onChangeValue("Qbody", newValue)
                                                                        // }
                                                                    }}
                                                                    // initialValue={values?.Qbody}
                                                                    value={values?.Qbody}
                                                                    id={"Qbody_editor"}
                                                                    init={{
                                                                        key: "1",
                                                                        height: 500,
                                                                        selector: "#Qbody_editor_add_new_question",
                                                                        images_upload_handler: image_upload_handler_fun,
                                                                        menubar: false,
                                                                        toolbar_mode: 'scrolling',
                                                                        plugins: ["anchor", "autolink", "autoresize", "codesample", "spellchecker", "paste", "image", "file", "imagetools", "lists", "link", "media", "noneditable", "table", "template"],
                                                                        toolbar: "urldialog  undo redo | fontselect | fontsizeselect | styleselect | bold italic underline strikethrough | justifyleft justifycenter justifyright justifyfull | bullist numlist | outdent indent hr |  link unlink | image | file | sub sup | forecolor backcolor forecolorpicker backcolorpicker|  removeformat formatselect | tablecontrols table row_props cell_props delete_col delete_row col_after col_before row_after row_before split_cells merge_cells | indigoDeepLink|fontsize",
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; overflow-y:scroll !important; }',
                                                                        setup: (editor) => {

                                                                            editor.ui.registry.addButton('urldialog', {
                                                                                icon: 'browse',
                                                                                text: 'Deep Link',
                                                                                onAction: () => {
                                                                                    // show Deep Link section on click
                                                                                    toggleDeepLinksectionVisiblity("Qbody_editor_add_new_question")

                                                                                }
                                                                            });
                                                                            //    window evetn here
                                                                            window.addEventListener("message", (event) => {
                                                                                if (event.data.cmd == "insertDeepLink_Qbody_editor_add_new_question") {
                                                                                    let name = event.data.value.name;
                                                                                    let pageType = event.data.value.pageType;
                                                                                    let linkDestination = event.data.value.linkDestination;
                                                                                    editor.insertContent(`[deeplink]${pageType}|${linkDestination}|${name}[/deeplink]`);
                                                                                }
                                                                            });


                                                                        },

                                                                    }
                                                                    }
                                                                />



                                                            </nav>
                                                        </Tab>
                                                        <Tab eventKey="choice" title="Question Choices">
                                                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb">
                                                                <div className='row'>
                                                                    {
                                                                        QuestionChoiceAiTextEditorVisiblity

                                                                            ?
                                                                            <textarea ref={QuestionChoiceAiTextEditorRef} rows={8} style={{ "width": "99%" }} placeholder='Paste your content here.' />
                                                                            :

                                                                            questionChoices && questionChoices.map((item, index) => (
                                                                                <div className='col-md-12 mb-3' key={index}>

                                                                                    <div className="row align-items-center ">
                                                                                        <div className="col-md-1 text-end">
                                                                                            <input
                                                                                                name="isCorrect"
                                                                                                type="radio"
                                                                                                className="form-check-input"
                                                                                                checked={item?.isCorrect}
                                                                                                onChange={(e) => getChoiceRecord(e.target.name, e.target.checked, index)}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-10 d-flex align-items-center ">
                                                                                            <span className='mx-2 h6'>{String.fromCharCode(65 + index)})</span>
                                                                                            <textarea rows={3} value={item.choice} className="form-control" name="choice"
                                                                                                onChange={(e) => getChoiceRecord(e.target.name, e.target.value, index)}  >

                                                                                            </textarea>
                                                                                        </div>
                                                                                        <div className="col-md-1">
                                                                                            <button className='border-0 bg-light' type='button' onClick={() => handleDeleteChoice(index)}><FontAwesomeIcon size='lg' className=' text-danger' style={{ cursor: 'pointer' }} icon={faCircleXmark} /></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            ))

                                                                    }

                                                                    <div className='text-end col-2'>
                                                                        {
                                                                            QuestionChoiceAiTextEditorVisiblity ?

                                                                                <Button variant={"primary btn-sm"} className='mt-3' type="button"
                                                                                    onClick={(e) => handleGenrateChoicesWithAI()}>
                                                                                    Genrate
                                                                                </Button>
                                                                                :
                                                                                <Button variant={"primary btn-sm"} disabled={(questionChoices.length == 26)} className='mt-3' type="button" onClick={(e) => handleAddChoices()}>
                                                                                    Add Choice
                                                                                </Button>
                                                                        }
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <Button variant={`${QuestionChoiceAiTextEditorVisiblity ? "info" : "primary"} btn-sm`} className='mt-3' type="button"
                                                                            onClick={(e) => setQuestionChoiceAiTextEditorVisiblityFn(!QuestionChoiceAiTextEditorVisiblity)}>
                                                                            {QuestionChoiceAiTextEditorVisiblity ? "Genrate Manually" : "Genrate Choices With AI"}
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                            </nav>
                                                        </Tab>
                                                        <Tab eventKey="explanation" title="Question Explanation">
                                                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb"
                                                            // ref={changeEditorbg}
                                                            >
                                                                <Editor
                                                                    apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                                                                    onEditorChange={(newValue, editor) => {
                                                                        // if (editor.isDirty()) {
                                                                        onChangeValue("QExplanation", newValue)
                                                                        // }
                                                                    }}
                                                                    value={values?.QExplanation}
                                                                    id={"QExplanation_editor"}
                                                                    init={{
                                                                        key: "2",
                                                                        height: 500,
                                                                        selector: "#QExplanation_editor_add_new_question",
                                                                        images_upload_handler: image_upload_handler_fun,
                                                                        menubar: false,
                                                                        toolbar_mode: 'scrolling',
                                                                        plugins: ["anchor", "autolink", "autoresize", "codesample", "spellchecker", "paste", "image", "file", "imagetools", "lists", "link", "media", "noneditable", "table", "template"],
                                                                        toolbar: "urldialog  undo redo | fontselect | fontsizeselect | styleselect | bold italic underline strikethrough | justifyleft justifycenter justifyright justifyfull | bullist numlist | outdent indent hr |  link unlink | image | file | sub sup | forecolor backcolor forecolorpicker backcolorpicker|  removeformat formatselect | tablecontrols table row_props cell_props delete_col delete_row col_after col_before row_after row_before split_cells merge_cells | indigoDeepLink|fontsize",
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; overflow-y:scroll !important; }',
                                                                        setup: (editor) => {

                                                                            editor.ui.registry.addButton('urldialog', {
                                                                                icon: 'browse',
                                                                                text: 'Deep Link',
                                                                                onAction: () => {
                                                                                    // show Deep Link section on click
                                                                                    toggleDeepLinksectionVisiblity("QExplanation_editor_add_new_question")

                                                                                }
                                                                            });
                                                                            //    window evetn here
                                                                            window.addEventListener("message", (event) => {
                                                                                if (event.data.cmd == "insertDeepLink_QExplanation_editor_add_new_question") {
                                                                                    let name = event.data.value.name;
                                                                                    let pageType = event.data.value.pageType;
                                                                                    let linkDestination = event.data.value.linkDestination;
                                                                                    editor.insertContent(`[deeplink]${pageType}|${linkDestination}|${name}[/deeplink]`);
                                                                                }
                                                                            });


                                                                        },

                                                                    }
                                                                    }
                                                                />
                                                            </nav>
                                                        </Tab>
                                                        <Tab eventKey="References" title=" Question References">
                                                            <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block bg-light  mx-2 p-4 rounded-2 w-100" aria-label="breadcrumb"
                                                            // ref={changeEditorbg}
                                                            >
                                                                <Editor
                                                                    apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
                                                                    onEditorChange={(newValue, editor) => {
                                                                        // if (editor.isDirty()) {
                                                                        onChangeValue("QReference", newValue)
                                                                        // }
                                                                    }}
                                                                    value={values?.QReference}
                                                                    id={"QReference_editor"}
                                                                    init={{
                                                                        key: "4",
                                                                        height: 500,
                                                                        selector: "#QReference_editor_add_new_question",
                                                                        images_upload_handler: image_upload_handler_fun,
                                                                        menubar: false,
                                                                        toolbar_mode: 'scrolling',
                                                                        plugins: ["anchor", "autolink", "autoresize", "codesample", "spellchecker", "paste", "image", "file", "imagetools", "lists", "link", "media", "noneditable", "table", "template"],
                                                                        toolbar: "urldialog  undo redo | fontselect | fontsizeselect | styleselect | bold italic underline strikethrough | justifyleft justifycenter justifyright justifyfull | bullist numlist | outdent indent hr |  link unlink | image | file | sub sup | forecolor backcolor forecolorpicker backcolorpicker|  removeformat formatselect | tablecontrols table row_props cell_props delete_col delete_row col_after col_before row_after row_before split_cells merge_cells | indigoDeepLink| fontsize",
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; overflow-y:scroll !important; }',
                                                                        setup: (editor) => {

                                                                            editor.ui.registry.addButton('urldialog', {
                                                                                icon: 'browse',
                                                                                text: 'Deep Link',
                                                                                onAction: () => {
                                                                                    // show Deep Link section on click
                                                                                    toggleDeepLinksectionVisiblity("QReference_editor_add_new_question")

                                                                                }
                                                                            });
                                                                            //    window evetn here
                                                                            window.addEventListener("message", (event) => {
                                                                                if (event.data.cmd == "insertDeepLink_QReference_editor_add_new_question") {
                                                                                    let name = event.data.value.name;
                                                                                    let pageType = event.data.value.pageType;
                                                                                    let linkDestination = event.data.value.linkDestination;
                                                                                    editor.insertContent(`[deeplink]${pageType}|${linkDestination}|${name}[/deeplink]`);
                                                                                }
                                                                            });


                                                                        },

                                                                    }
                                                                    }
                                                                />



                                                            </nav>
                                                        </Tab>
                                                    </Tabs>
                                                    <div >
                                                        <div className="row align-items-center mx-3">
                                                            <div className="col-6">
                                                                <ButtonGroup>
                                                                    {modeOfDifficulty.map((radio, idx) => (
                                                                        <ToggleButton
                                                                            key={idx}
                                                                            id={`radio-${idx}`}
                                                                            type="radio"
                                                                            variant={(idx == 0) ? 'outline-warning' : (idx == 1) ? 'outline-success' : 'outline-danger'}
                                                                            name="modeOfDifficulty"
                                                                            value={radio.name}
                                                                            checked={values.modeOfDifficulty === radio.name}
                                                                            onChange={(e) => onChangeValue(e.target.name, e.target.value)}
                                                                        >
                                                                            {radio.name}
                                                                        </ToggleButton>
                                                                    ))}
                                                                </ButtonGroup>
                                                            </div>
                                                            <div className='col-6 d-flex justify-content-end '>
                                                                <ToggleButton
                                                                    className="mx-3"
                                                                    id="toggle-check"
                                                                    type="checkbox"
                                                                    variant="outline-dark"
                                                                    checked={values.publishStatus}
                                                                    name='publishStatus'
                                                                    onChange={(e) => onChangeValue(e.target.name, e.target.checked)}
                                                                >
                                                                    Publish
                                                                </ToggleButton>
                                                                <Button variant="primary" type="submit" >
                                                                    Save Changes
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );

}

export default AddNewQBankSectionTopModel 