import './App.css';
import TopNav from './components/nav/TopNav';
import SideNav from './components/nav/SideNav';
import EditorSection from './components/main/EditorSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PolicySection from "./components/main/PolicySection";
import PhoneDirectory from './components/main/PhoneDirectory';
import RolesAndPermissions from './components/main/RoleAndPermission';
import useFetch from './global/callMasterData';
import Login from './components/main/Login';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { getUserDataFromFirebaseAuth } from './firebaseDb/auth';
import { signOut } from './firebaseDb/auth';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { captureException } from '@sentry/react';
import QBank from './components/main/QBank';
import QBankSection from './components/main/QBankSection';
import ForgotPassword from './components/main/ForgotPassword';



function App() {
  let auth = getAuth();
  const [user, loading] = useAuthState(auth);
  let [userPermission, setUserPermission] = useState({});
  const [authenticationStatus, setAuthenticationStatus] = useState(-1);
  const [userIdToken, setUserIdToken] = useState("");
  const [error, setError] = useState(true);
  // const [error, setError] = useState(false);

  // let { error, setError } = useFetch("/editor_content", authenticationStatus, setAuthenticationStatus, user, loading, userIdToken);
  // const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          const userIdToken = await user.getIdToken();
          setUserIdToken(userIdToken)
          if (localStorage.getItem("JWTToken") == null) {
            localStorage.setItem("JWTToken", userIdToken.trim());
          }
          try {
            const res =
              await getUserDataFromFirebaseAuth({
                userId: user.uid,
              });
            if (!res.error && res.errorCode == 200) {
              setAuthenticationStatus(true);

            } else if (res.errorMessage == "Invalid token") {
              localStorage.clear();
              signOut();
            }
          } catch (err) {
            // console.log(err, "err")
            setAuthenticationStatus(false);
            captureException(error, "error");
          }
        }
      }

    })();
    // return () => {
    //   dispatch(emptyReduxData());
    // }
  }, [user, loading]);

  // }, [userPermission]);

  // Layout component that includes TopNav and SideNav
  const Layout = ({ children }) => {
    let { error, setError } = useFetch("/editor_content", authenticationStatus, setAuthenticationStatus, user, loading, userIdToken);
    // setError(error);

    return (

      <>
        {authenticationStatus && authenticationStatus != -1 ?

          <>
            <TopNav setAuthenticationStatus={setAuthenticationStatus} userPermission={userPermission} setUserPermission={setUserPermission} />
            <SideNav userPermission={userPermission} />
            {children}

          </>
          :
          <>
            {!loading && <Navigate to={'/login'} />}
          </>
        }

      </>
    );
  }
  return <>
    <Router>
      <Routes>
        {
          (Object.keys(userPermission).length > 0) &&
          <>
            {userPermission.roles.content && <Route exact path='/' element={<Layout><EditorSection error={error} /></Layout>} />}
            {userPermission.roles.content && <Route exact path='/Q-Bank' element={<Layout><QBank error={error} userId={user} /></Layout>} />}
            {userPermission.roles.content && <Route exact path='/Q-Bank-Section' element={<Layout><QBankSection error={error} userId={user} /></Layout>} />}
            {userPermission.roles.policy && <Route exact path='/policy' element={<Layout><PolicySection error={error} userId={user} /></Layout>} />}
            {userPermission.roles.policy && <Route exact path='/phone-direactory' element={<Layout><PhoneDirectory error={error} userId={user} /></Layout>} />}
            {userPermission.roles.superAdmin && <Route exact path='/roles-and-permissions' element={<Layout><RolesAndPermissions error={error} /></Layout>} />}
          </>
        }
        <Route path='/login' element={<Login setAuthenticationStatus={setAuthenticationStatus} authenticationStatus={false} userPermission={userPermission} setUserPermission={setUserPermission} user={user} />} />
        <Route path='/forgotpassword' element={<ForgotPassword />}></Route>
        <Route path='*' element={<Navigate to={'/login'} />} />
      </Routes>
    </Router>
  </>


}

export default App;
