import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

function Otp_Verify_Model({ email, loading, setLoading }) {


    const dispatch = useDispatch();


    const [otp, setOtp] = useState({
        0: '',
        1: '',
        2: '',
        3: '',
    });
    // attach event listener
    useEffect(() => {

        document.addEventListener('DOMContentLoaded', (event) => {
            const inputs = document.querySelectorAll('.otp-letter-input');
            inputs.forEach((input, index) => {
                input.addEventListener('input', () => {
                    if (input.value.length === 1 && index < inputs.length - 1) {
                        inputs[index + 1].focus();
                    }
                });

                input.addEventListener('keydown', (e) => {
                    if (e.key === 'Backspace' && index > 0 && input.value.length === 0) {
                        inputs[index - 1].focus();
                    }
                });

                input.addEventListener('paste', (e) => {
                    const paste = e.clipboardData.getData('text');
                    paste.split('').forEach((char, i) => {
                        if (i < inputs.length) {
                            inputs[index + i].value = char;
                            if (index + i < inputs.length - 1) {
                                inputs[index + i + 1].focus();
                            }
                        }
                    });
                    e.preventDefault();
                });

                input.addEventListener('keypress', (e) => {
                    if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                    }
                });
            });

        });
        return () => { document.removeEventListener('DOMContentLoaded') }
    }, []);


    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();

        const otpCode = Object.values(otp).join('');
        if (otpCode.length === 4) {
            dispatch({ type: "VERIFY_OTP", otp: otpCode, email: email });
            setLoading(true);
        }

    }
    const handleOnChangeEvent = (index, value) => {
        setOtp({ ...otp, [index]: value });
    }

    return (
        <>
            <h1 className="mb-4">Verify OTP</h1>
            <form onSubmit={handleSubmit} >
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-2 ">
                        <input className="otp-letter-input" name="code-1" type="text" maxLength="1" onChange={(e) => { handleOnChangeEvent(0, e.target.value) }} />
                    </div>
                    <div className="col-md-2">
                        <input className="otp-letter-input" name="code-1" type="text" maxLength="1" onChange={(e) => { handleOnChangeEvent(1, e.target.value) }} />
                    </div>
                    <div className="col-md-2">
                        <input className="otp-letter-input" name="code-1" type="text" maxLength="1" onChange={(e) => { handleOnChangeEvent(2, e.target.value) }} />
                    </div>
                    <div className="col-md-2">
                        <input className="otp-letter-input" name="code-1" type="text" maxLength="1" onChange={(e) => { handleOnChangeEvent(3, e.target.value) }} />
                    </div>
                    <div className='col-md-12'>
                        <Button variant="primary" type="submit" className='w-25 mt-5' disabled={Object.values(otp).includes("") || loading} >Verify</Button>
                    </div>
                </div>
            </form>
        </>

    )
}

export default Otp_Verify_Model