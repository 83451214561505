import axios from "axios";
import { captureException } from '@sentry/react';
import { FULL_CONTENT_ACCESS, POLICIES_PAGE_ACCESS, CREATE_NEW_USER_IN_FIREBASE_AUTHENTICATION, SET_DATA_TO_FIREBASE_DB, UPDATE_DATA_IN_FIREBASE_DB, DELETE_USER_IN_FIREBASE_AUTHENTICATION, UPDATE_USER_INFO_IN_FIREBASE_AUTHENTICATION, SEND_OTP_FOR_FORGOT_PASSWORD, BASE_URL } from "../../constant/constant";
// add new user method will be hereexport const add_new_user = async (data) => {

export const add_new_user = async (data) => {

    let resData = {
        error: false,
        message: ""
    };

    let setData = "";

    const userInfoData = {
        OTP: "",
        OTPVerified: true,
        email: data.data.userEmail,
        lot: data.data.userLot,
        modeOfLogin: "Regular",
        name: data.data.userName,
        roles: {
            appuser: true,
            content: data.data[FULL_CONTENT_ACCESS],
            policy: data.data[POLICIES_PAGE_ACCESS]
        }


    }

    let userUid = "";
    // call to add new user in authentication
    await axios.post(CREATE_NEW_USER_IN_FIREBASE_AUTHENTICATION, { email: data.data.userEmail, password: data.data.userPassword }).then((res) => {
        if (!res.data.error) {
            userUid = res.data.user;

        } else {
            resData.error = false;
            resData.message = res.data.errorMessage;
        }

    }).catch((err) => {
        resData.error = false;
        resData.message = err;
        captureException(err, "error");
    })

    if (userUid != "") {

        // call to add new user in firebase database
        setData = {
            // user Info Data will be here
            ["/userinfo/" + userUid]: userInfoData,

        }
        if (data.data[POLICIES_PAGE_ACCESS]) {
            setData["/policies/users/" + userUid] = {
                userEmail: data.data.userEmail,
                amionLogin: {
                    [data.data.userInstitution]: {
                        key: "exists"
                    }
                }
            }

        }

        if (data.data[FULL_CONTENT_ACCESS]) {
            setData["/whitelist/" + userUid] = true;
        }
        await axios.post(UPDATE_DATA_IN_FIREBASE_DB, { AllData: setData }, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("JWTToken").trim()}`,
            }
        }).then((res) => {

            if (!res.data.error) {

                resData.error = true;
            } else {
                resData.error = false;
                resData.message = res.data.errorMessage;
            }
        }).catch((err) => {
            resData.error = false;
            resData.message = err;
            captureException(err, "error");
        })

    }
    return resData;


}

export const delete_user = async (data) => {
    let resData = {
        error: false,
        message: ""
    };
    let userUid = data.key;
    let userStatus = false;
    // delete user from firebase authentication
    await axios.post(DELETE_USER_IN_FIREBASE_AUTHENTICATION, { userUid: userUid }).then((res) => {
        if (!res.data.error) {
            userStatus = true;
        } else {
            resData.error = false;
            resData.message = res.data.errorMessage;
        }
    })
    if (userStatus) {
        let setData = {

            ["/userinfo/" + userUid]: null,
            ["/policies/users/" + userUid]: null,
            ["/whitelist/" + userUid]: null,

        }
        await axios.post(UPDATE_DATA_IN_FIREBASE_DB, { AllData: setData }, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("JWTToken").trim()}`,
            }
        }).then((res) => {
            if (!res.data.error) {
                resData.error = true;
            } else {
                resData.error = false;
                resData.message = res.data.errorMessage;
            }
        }).catch((err) => {
            resData.error = false;
            resData.message = err;
            captureException(err, "error");
        })
    }

    return resData;
}

export const update_user = async (data) => {

    let resData = {
        error: false,
        message: ""
    };

    let setData = "";

    const userInfoData = {
        OTP: "",
        OTPVerified: true,
        email: data.data.userEmail,
        lot: data.data.userLot,
        modeOfLogin: "Regular",
        name: data.data.userName,
        roles: {
            appuser: true,
            content: data.data[FULL_CONTENT_ACCESS],
            policy: data.data[POLICIES_PAGE_ACCESS],
            superAdmin: data.isSuperAdmin,
        }

    }

    let userUid = data.key;
    let userStatus = false;
    // call to add new user in authentication
    if (data.data.userPassword != "") {

        await axios.post(UPDATE_USER_INFO_IN_FIREBASE_AUTHENTICATION, { userUid: userUid, email: data.data.userEmail, password: data.data.userPassword }).then((res) => {

            if (!res.data.error) {

                userStatus = true;

            } else {
                resData.error = false;
                resData.message = res.data.errorMessage;
            }

        }).catch((err) => {
            resData.error = false;
            resData.message = err;
            captureException(err, "error");
        })
    }

    // call to add new user in firebase database
    setData = {
        // user Info Data will be here
        ["/userinfo/" + userUid]: userInfoData,

    }
    if (data.data[POLICIES_PAGE_ACCESS]) {
        setData["/policies/users/" + userUid] = {
            userEmail: data.data.userEmail,
            amionLogin: {
                [data.data.userInstitution]: {
                    key: "exists"
                }
            }
        }

    } else {
        setData["/policies/users/" + userUid] = null;
    }

    if (data.data[FULL_CONTENT_ACCESS]) {
        setData["/whitelist/" + userUid] = true;
    } else {
        setData["/whitelist/" + userUid] = null;

    }


    await axios.post(UPDATE_DATA_IN_FIREBASE_DB, { AllData: setData }, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("JWTToken").trim()}`,
        }
    }).then((res) => {
        if (!res.data.error) {

            resData.error = true;
        } else {
            resData.error = false;
            resData.message = res.data.errorMessage;
        }
    }).catch((err) => {
        resData.error = false;
        resData.message = err;
        captureException(err, "error");

    })
    return resData;
}

export const forgotPasswordOtpSentMethod = async (data) => {
    let resData = {
        error: true,
        message: "",
        data:{}
    };
    try {

        const response = await axios.post(SEND_OTP_FOR_FORGOT_PASSWORD, { email: data }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        if (response.data.success) {
            resData.error = false;
            resData.message = response.data.message;
            resData.data = response?.data?.sendRes || {};
        } else {
            resData.error = true;
            resData.message = response.data.message;
        }

    } catch (err) {
        resData.error = true;
        resData.message = err;
        captureException(err, "error");
    }
    return resData;

}

export const verifyForgotPasswordOtp = async ({ email, otp }) => {
    let resData = {
        error: true,
        message: ""
    };
    try {

        const response = await axios.post(BASE_URL + "/verifyForgotPasswordOtp", { email: email, otp: otp }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        if (response.data.success) {
            resData.error = false;
            resData.message = response.data.message;
        } else {
            resData.error = true;
            resData.message = response.data.message;
        }

    } catch (err) {
        resData.error = true;
        resData.message = err;
        captureException(err, "error");
    }
    return resData;

}

export const updatePasswordInAuth = async ({ email, password,userId,time }) => {
    let resData = {
        error: true,
        message: ""
    };
    try {

        const response = await axios.post(BASE_URL + "/updateUserPasswordByForgotPassword", { email: email, password: password,userId:userId,time:time }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        if (!response.data.error) {
            resData.error = false;
            resData.message = response.data.errorMessage;
        } else {
            resData.error = true;
            resData.message = response.data.errorMessage;
        }

    } catch (err) {
        resData.error = true;
        resData.message = err;
        captureException(err, "error");
    }
    return resData;

}