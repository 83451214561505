import { createSlice, current } from "@reduxjs/toolkit";
import { consoleFxn } from "../../console/console";

const initialState = {

    AllData: "",
    rootLevelKey: [],
    crudAction: {},
    forgotPasswordOtpSent: '',
    forgotPasswordOtpSentData: {},
    varifyOtp: '',
    userPasswordUpdateStatus: '',
    QBankQuestionChoices: [],
    error: null

}

export const reduxOperations = createSlice(
    {
        name: "AllData",
        initialState: initialState,
        reducers: {
            storeAllData: (state, action) => {
                let data = action.payload.data;
                let key = action.payload.key;
                let preData = state.AllData;
                state.AllData = { ...preData, [key]: data };
                // store root level all keys for redirect to home page directly in side search bar input
                state.rootLevelKey = Object.keys(state.AllData);
            },
            editData: (state, action) => {
                const editValue = action.payload.value;
                const editPropt = action.payload.propt;
                if (editPropt[0] == "body") {
                    state.crudAction = { type: "edit", value: "Data Changed Successfully" };

                } else {
                    state.crudAction = { type: "edit", value: editValue };
                }
            },
            setEditedStatus: (state, action) => {
                state.crudAction = action.payload
            },
            deleteData: (state, action) => {
                const deleteValue = action.payload.value;
                // state.AllData = redData(state.AllData, editKey, "", "", "delete");
                state.crudAction = { type: "delete", value: deleteValue };

            },
            addNewData: (state, action) => {
                const editValue = action.payload;
                const latestValue = editValue.text !== undefined ? editValue.text : (editValue?.data && editValue?.data[editValue?.data?.length - 1]?.name) ? editValue.data[editValue.data.length - 1].name : "";  //in case of add new institute we set this logic here
                state.crudAction = { type: "addNew", value: latestValue, navPush: editValue.navPush };
            },
            forgotPasswordOtpSent: (state, action) => {
                state.forgotPasswordOtpSent = "success";
                state.forgotPasswordOtpSentData = action.payload;
            },
            verifyOtp: (state, action) => {
                state.varifyOtp = action.payload
            },
            userPasswordUpdateStatus: (state, action) => {
                state.userPasswordUpdateStatus = action.payload
            },
            errorInOperation: (state, action) => {
                state.error = action.payload;
            },
            emptyReduxData: (state, action) => {
                return initialState;
            },
            clearStep: (state, action) => {
                let key = action.payload.key;
                let value = action.payload.value;
                state[key] = value;
            },
            //======================//
            AiGenratedQuestionChoice: (state, action) => {
                state.QBankQuestionChoices = action.payload
            },
            clearAll: (state, action) => {
                state.forgotPasswordOtpSent = '';
                state.varifyOtp = '';
                state.userPasswordUpdateStatus = '';
                state.forgotPasswordOtpSentData = {};
                state.error = null

            }
        }
    }
);
const redData = (obj = {}, key, editValue = "", propt = "", type = "") => {
    const recursiveSearch = (obj = {}) => {

        if (!obj || typeof obj !== 'object') {
            return
        };

        Object.keys(obj).forEach(function (k) {
            if (k === key) {
                if (type == "edit") {

                    obj[key]["text"] = editValue
                }
                if (type == "delete") {
                    obj[key] = ""
                }
                if (type == "addNew") {

                    obj[key] = { ...obj[key], editValue }
                }


            };
            recursiveSearch(obj[k]);
        });
    }

    recursiveSearch(obj);
    return obj;


}

export const { storeAllData, editData, setEditedStatus, deleteData, addNewData, errorInOperation, emptyReduxData, forgotPasswordOtpSent, clearStep, verifyOtp, userPasswordUpdateStatus, clearAll, AiGenratedQuestionChoice } = reduxOperations.actions
export default reduxOperations.reducer