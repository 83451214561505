import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxOperations from './features/fetchAllRecord';
import createSagaMiddleware from "redux-saga";
import rootSaga from '../saga/rootSaga';
import editDataSaga from '../saga/saga';
// const persistConfig = {
//     key: 'root',
//     storage,
//   }
// const persistedReducer = persistReducer(persistConfig, fetchAllData)
// export const store = configureStore({
//     reducer: persistedReducer
// })
// const sagaMiddleware = createSagaMiddleware();
// export const store = configureStore({
//         reducer:reduxOperations,
//         middleware:[sagaMiddleware]
//     })
//     sagaMiddleware.run(rootSaga);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: reduxOperations,
    middleware: [sagaMiddleware]
})
sagaMiddleware.run(rootSaga);

// export const  persistor = persistStore(store)

