import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { faPlus, faPenToSquare, faTrash, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';
import ToastDefault, { notify } from '../toasts/ToastDefault';
import Button from 'react-bootstrap/Button';
import { ADD_NEW_INSTITUTE, ADD_NEW_AT_ROOT, DELETE_SECTION, EDIT_TEXT_AND_SHORT_TEXT, ListPage } from '../../constant/constant';
import Swal from 'sweetalert2';
const InstitutionModel = ({ setHospitalModelVisablityFunction, data, linkDestination }) => {

    // handle new hospital form submit 
    const dispatch = useDispatch()

    // on add new form submit 
    const [addNew, setAddNewInput] = useState({
        name: "",
        passcode: "",
    });

    let name, value;
    const getRecord = (event) => {

        name = event.target.name;
        value = event.target.value;
        setAddNewInput({ ...addNew, [name]: value });
    }
    const handleNewInstituteFormSubmit = (e) => {
        e.preventDefault();
        if (addNew.name.trim() != "" && addNew.passcode.trim() != "") {

            let newIntitutionData = {
                key: addNew.passcode,
                linkDestination: linkDestination + "documents/" + addNew.passcode + "/",
                linkType: 1,
                navPush: ListPage,
                shortText: addNew.name,
                text: addNew.name
            }

            dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { "institutions/": [...data, { "amionLogin": addNew.passcode, "name": addNew.name, "documentKey": addNew.passcode }], ["documents/" + addNew.passcode + "/"]: newIntitutionData }, key: linkDestination, propt: ["institutions/", "documents/" + addNew.passcode + "/"], operationType: "addNew" });

            e.target.reset();

        } else {

            notify("All Field Must Be Filled!", "error");
        }


    }

    // -----------------handle delete event----------------//
    const handleDeleteEvent = (deletedValue, index) => {
        Swal.fire({
            title: "Are you sure you want to delete \"" + deletedValue + "\" ?",
            // text: "All chiled node of \"" + deletedValue + "\" will also be deleted",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({
                    type: EDIT_TEXT_AND_SHORT_TEXT, value: { ["institutions/" + index]: null, ["documents/" + data?.[index]?.documentKey]: null }, key: linkDestination, propt: ["institutions/" + index, "documents/" + data?.[index]?.documentKey], operationType: "delete"
                });
            }
        });

    }
    // handle toggale edit event 
    const [editValue, setEditValue] = useState({});
    const handleToggleEditEvent = (index, editInstitutionValue = "", editPasscodeValue) => {

        setAddNewInput({ name: editInstitutionValue, passcode: editPasscodeValue })
        setEditValue({ index: index })

    }

    function edit({ propt, index }) {

        if (addNew.name.trim() != "" && addNew.passcode.trim() != "") {

            dispatch({ type: EDIT_TEXT_AND_SHORT_TEXT, value: { [index + "/name/"]: addNew.name, [index + "/amionLogin/"]: addNew.passcode }, key: linkDestination + "institutions/", propt });
            
            setAddNewInput({
                name: "",
                passcode: "",
            });
            setEditValue({});

        } else {

            notify("All field required !", "error");
        }

    }
    /////////// close editing section ///////////////

    return (
        <>
            {/* <ToastDefault /> */}
            <div className="modal modal-right show d-block " id="hospitalModel" tabIndex="-1" role="dialog" aria-labelledby="hospitalModel" style={{ "paddingRight": "17px", "background": "rgba(0,0,0,0.6)" }} aria-modal="true" >
                <div className="modal-dialog" role="document" style={{ maxWidth: "730px" }}>
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold ">Institutions</h5>
                            <button type="button" className="btn-close" onClick={() => setHospitalModelVisablityFunction()}></button>
                        </div>
                        <div className="modal-body">

                            <form onSubmit={(e) => { handleNewInstituteFormSubmit(e) }}>
                                <div className="row">
                                    <div className="col-md-6">

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Name" onChange={(e) => getRecord(e)} name='name' />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Passcode</Form.Label>
                                            <Form.Control type="password" placeholder="Enter Amion Passcode" onChange={(e) => getRecord(e)} name='passcode' />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <Button variant="primary" type="submit">
                                        {/* {loading.isLoading ? 'Submiting…' : 'Submit'} */}
                                        Submit
                                    </Button>
                                </div>
                            </form>

                            <div>
                                <div className="mt-2">
                                    <div className="">
                                        <div className="">
                                            <h2 className="card-title mt-5 mb-2  text-center">List of Institutions</h2>
                                            <hr />
                                            <table className="table table-hover table-responsive ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Institution</th>
                                                        <th scope="col">Passcode</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {(data) ? data.map((key, index) => {

                                                        return (
                                                            <>
                                                                <tr key={index} >
                                                                    <td style={{ wordBreak: "break-word" }}>
                                                                        {(editValue.index == index) ?
                                                                            <>
                                                                                <input type="text" style={{ width: "10rem" }} className='fw-bold' name='name' onChange={(e) => getRecord(e)} value={addNew.name} />
                                                                            </>
                                                                            :
                                                                            <b>  {key.name}</b>
                                                                        }
                                                                    </td>
                                                                    <td style={{ wordBreak: "break-word" }}>
                                                                        {(editValue.index == index) ?
                                                                            <>
                                                                                <input type="text" style={{ width: "10rem" }} className='fw-bold' name='passcode' onChange={(e) => getRecord(e)} value={addNew.passcode} />

                                                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => setEditValue({})} className='ms-3 text-primary' icon={faXmark} />

                                                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} className='ms-3 text-primary' onClick={() => edit({ propt: [index + "/name/", index + "/amionLogin/"], index: index })} icon={faCheck} />
                                                                            </>
                                                                            :
                                                                            <b>  {key.amionLogin}</b>
                                                                        }
                                                                    </td>
                                                                    <td>

                                                                        <FontAwesomeIcon className='text-success mx-3' size='xl' onClick={() => handleToggleEditEvent(index, key.name, key.amionLogin)} icon={faPenToSquare} />

                                                                        <FontAwesomeIcon className='text-danger' size='xl' onClick={() => handleDeleteEvent(key.name, index)} icon={faTrash} />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={3} className='text-center fw-bold text-danger fs-5'>
                                                                No Record Found
                                                            </td>
                                                        </tr>

                                                    }
                                                </tbody >
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default InstitutionModel
